<template>
  <div class="about">
    <div class="item left">
      <div style="height:39%;width:100%">
        <scroll-table :isHaveData='flag' />
      </div>
      <div style="height:58%;width:100%;">
        <operation-pie :isHaveData='flag' />
      </div>
    </div>
    <div class="item middle">
      <map-data :isHaveData='flag' />
    </div>
    <div class="item right">
      <div style="width:100%;height:34%">
        <line-chart :isHaveData='flag' />
      </div>
      <div style="width:100%;height:63%">
        <pie-groups :isHaveData='flag' />
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from './innovate/lineChart.vue'
import mapData from './innovate/mapData.vue'
import OperationPie from './innovate/operationPie.vue'
import PieGroups from './innovate/pieGroups.vue'
import ScrollTable from './innovate/scrollTable.vue'
export default {
  components: { mapData, ScrollTable, OperationPie, LineChart, PieGroups },
  data () {
    return {
      flag: 0
    }
  },
  computed: {
    innovateData() {
      return this.$store.state.allData.innovate;
    },
  },
  watch: {
    innovateData: {
      handler: function (val) {
        if (val) {
          this.flag++
        }
      },
      immediate: true
    }
  }
}
</script>
<style scoped>
.about{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.left{
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.middle{
  width: 40%;
  height: 100%;
}
.right{
  width: 28%;
  height: 100%;
  /* background-color: blueviolet; */
    display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
