<template>
  <div class="about">
    <div id="left">
      <div class="item">
        <pie-chart-industry
        :isHaveData='flag'
        ></pie-chart-industry>
      </div>
      <div class="item">
        <pie-chart-class
        :isHaveData='flag'
        ></pie-chart-class>
      </div>
      <div class="item">
        <bar-chart-class
        :isHaveData='flag'
        ></bar-chart-class>
      </div>
      <div class="item">
        <bar-chart-num
        :isHaveData='flag'
        ></bar-chart-num>
      </div>
    </div>
    <div id="middle">
      <map-data
      :isHaveData='flag'
      style="height:64%;width:100%"
      ></map-data>
      <dv-decoration-5 style="width:100%;height:1%;" />
      <scroll-table
      :isHaveData='flag'
      style="height:32%;width:100%"
      ></scroll-table>
    </div>
    <div id="right">
      <div class="item">
        <table-view
        :isHaveData='flag'
        ></table-view>
      </div>
      <div class="item" id="special">
        <hydrograph
        :isHaveData='flag'
        ></hydrograph>
      </div>
      <div class="item">
        <bar-chart :isHaveData='flag' />
      </div>
    </div>
  </div>
</template>
<script>
import mapData from './total/mapData'
import ScrollTable from './total/scrollTable.vue'
import pieChartIndustry from './total/pieChartIndustry'
import PieChartClass from './total/pieChartClass.vue'
import BarChartClass from './total/barChartClass'
import BarChartNum from './total/barChartNum.vue'
import tableView from './total/tableView.vue'
import hydrograph from './total/hydrograph'
import Hydrograph from './total/hydrograph.vue'
import barChart from './total/barChart.vue'
export default {
  name: 'total',
  components:{ 
    mapData,
    ScrollTable,
    pieChartIndustry,
    PieChartClass,
    BarChartNum,
    BarChartClass,
    tableView,
    hydrograph,
    Hydrograph,
    barChart
    },
    computed: {
      totalData() {
        return this.$store.state.allData.total
      }
    },
    data () {
      return {
        flag: 0
      }
    },
    watch: {
      totalData: {
        handler: function (val, old) {
          if (val) {
            this.flag++
          }
        },
        deep: true
      }
    }
}
</script>
<style scoped>
.about{
  display: flex;
}
#left{
  flex: 3;
   display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
#middle{
  flex: 4;
  display: flex;
  flex-direction: column;
  padding:0 .125rem /* 10px -> .125rem */;
  padding-bottom: .25rem /* 20px -> .25rem */;
  justify-content: space-around;
}
#right{
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.item{
  width: 95%;
  height: 24%;
  /* background-color: rosybrown; */
}
#special{
  height: 48%;
}
</style>
