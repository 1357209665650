<template>
  <div id="compontent">
    <dv-border-box-7 backgroundColor="rgba(93, 93, 95,0.3)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">近五</span>年专利运营量趋势
        </div>
        <div class="content">
          <echart
            id="lineChartFF"
            :options="options"
            :data="yearArr"
            :isChange="true"
          />
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import echart from "../../components/echarts/echart.vue";
export default {
  components: { echart },
  props: ['isHaveData'],
  data() {
    return {
      options: "",
      allData: '',
      yearArr: [],
      xkArr: [],
      zyArr: [],
      zrArr: []
    };
  },
  mounted() {
    if (this.allData) this.setOption()
  },
  methods: {
    setOption () {
        this.options = {
          tooltip: {
            trigger: "axis",
            confine: true,
            backgroundColor: "rgba(255,255,255,0.3)",
            textStyle: {
              color: "#FFF",
            },
          },
          legend: {
            x: "right",
            data: ['许可', '质押', '转让'],
            textStyle: {
              color: "#FFF",
              fontSize: "0.175rem",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: this.yearArr,
              axisLabel: {
                fontSize: "0.15rem",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                fontSize: "0.15rem",
              },
            },
          ],
          series: [
            {
              name: '许可',
              type: "line",
              stack: "a",
              data: this.xkArr,
            },
            {
              name: '质押',
              type: "line",
              stack: "b",
              data: this.zyArr,
            },
            {
              name: '转让',
              type: "line",
              stack: "2",
              data: this.zrArr,
            },
          ],
        };
    }
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        if (val) {
          this.allData = this.$store.state.allData.patent.operateNumTrend
        }
      },
      immediate: true
    },
    allData: {
      handler: function (val) {
        if (val) {
          let data = this.allData
          for (let i = 0; i < data.length; i++) {
            let item = data[i]
            this.yearArr.push(data[i].year)
            this.xkArr.push(data[i].data.xk)
            this.zyArr.push(data[i].data.zy)
            this.zrArr.push(data[i].data.zr)
          }
          this.setOption()
        }
      },
      immediate: true
    },
  },
};
</script>
<style scoped>
#compontent {
  height: 96%;
  width: 100%;
}
.out {
  height: 95%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 10%;
  font-size: 0.225rem /* 18px -> .225rem */;
  font-weight: bold;
  line-height: 40px;
}
.content {
  height: 88%;
}
</style>