<template>
  <div class="about">
    <div class="left">
      <table-view :isHaveData='flag' />
    </div>
    <div class="middle" :isHaveData='flag'>
      <map-view />
    </div>
    <div class="right">
      <pie-groups :isHaveData='flag' />
    </div>
  </div>
</template>
<script>
import data from "../../public/jsData/borad";
import MapView from "./board/mapView.vue";
import PieGroups from "./board/pieGroups.vue";
import tableView from "./board/tableView.vue";
export default {
  components: { tableView, MapView, PieGroups },
  data() {
    return {
      compData: "",
      flag: 0
    };
  },
  computed: {
    boardData() {
      return this.$store.state.allData.total;
    },
  },
  mounted() {
    this.compData = data;
  },
  watch: {
    boardData: {
      handler: function (val) {
        if (val) {
          this.flag++
        }
      },
      immediate: true
    }
  }
};
</script>
<style scoped>
.about {
  display: flex;
  justify-content: space-around;
}
.left {
  width: 27%;
}
.middle {
  width: 45%;
}
.right {
  width: 27%;
}
</style>
