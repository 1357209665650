<template>
  <div id="compontent">
    <dv-border-box-10
      width="90%"
      height="95%"
      backgroundColor="RGBA(77, 144, 254,0.2)"
      id="border"
    >
      <dv-scroll-board
        :config="config"
        style="width: 95%; height: 80%; cursor: pointer"
        @click="clickChart"
      />
    </dv-border-box-10>
  </div>
</template>
<script>
export default {
  props: ["isHaveData"],
  data() {
    return {
      notChangeData: "",
      allData: "",
      config: {
        header: ["排名", "机构名称", "专利授权量"],
        data: [],
        waitTime: 1000,
        headerHeight: 40,
        oddRowBGC: "RGBA(77, 144, 254,0.1)",
        evenRowBGC: "RGBA(10, 39, 50,0.2)",
        headerBGC: "RGBA(77, 144, 254,0.8)",
      },
    };
  },
  mounted() {
    if (this.$store.state.allData.total) {
      this.notChangeData = this.$store.state.allData.total.scrollTable;
    }
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        this.notChangeData = this.$store.state.allData.total.scrollTable;
      },
    },
    notChangeData: {
      handler: function (val) {
        if (val) {
          this.allData = JSON.parse(JSON.stringify(this.notChangeData))
          for (let i = 0; i < this.allData.length; i++) {
            for (let j = 0; j < this.allData[i].length; j++) {
              if (i < 3) {
                let a =
                  "<span style='font-size:0.25rem;color:#fac858'>" +
                  this.allData[i][j] +
                  "</span>";
                this.allData[i][j] = a;
              } else {
                let a =
                  "<span style='font-size:0.2rem'>" +
                  this.allData[i][j] +
                  "</span>";
                this.allData[i][j] = a;
              }
            }
          }
          this.config = {
            header: [
              "<span style='font-size:0.28rem'>排名</span>",
              "<span style='font-size:0.28rem'>机构名称</span>",
              "<span style='font-size:0.28rem'>专利授权量</span>",
            ],
            data: this.allData,
            waitTime: 1000,
            headerHeight: 40,
            oddRowBGC: "RGBA(77, 144, 254,0.1)",
            evenRowBGC: "RGBA(10, 39, 50,0.2)",
            headerBGC: "RGBA(77, 144, 254,0.4)",
            align: ["center", "center", "center"],
          };
        }
      },
    },
  },
  methods: {
    clickChart(e) {
      window.open(
        "https://r.xjip.info/#/search?links=applicant:" +
          this.notChangeData[e.rowIndex][1]
      );
    },
  },
};
</script>
<style>
#compontent {
  width: 100%;
  height: 95%;
}
.border-box-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
#border {
}
.header {
  font-weight: bold;
}
</style>