<template>
  <div class="about">
    <div class="child top">
      <div class="item left">
        <scroll-table :data="compontentDataST" :name='titleName' />
      </div>
      <div class="item middle" style="height: 100%">
        <map-data @clickItem="clickItem($event)" :componentMapData="compontentDataMD" :componentMapALL='mapALL' />
      </div>
      <div class="item right">
        <operation :data="compontentDataOP" :name='titleName' />
      </div>
    </div>
    <dv-decoration-5 style="width: 100%; height: 2%" />
    <div class="child bottom">
      <innovate :data="compontentDataIN" :name='titleName'/>
    </div>
  </div>
</template>
<script>
//引入组件
import Innovate from "./industry/innovate";
import MapData from "./industry/mapData.vue";
import Operation from "./industry/operation.vue";
import ScrollTable from "./industry/scrollTable.vue";
export default {
  components: { MapData, ScrollTable, Operation, Innovate },
  name: "industry",
  data() {
    return {
      compontentDataST: "",
      compontentDataMD: "",
      compontentDataOP: "",
      compontentDataIN: "",
      flag: "PC",
      allData: "",
      mapALL: {
        PC: 0,
        CA: 0,
        PW: 0,
        FF: 0
      },
      titleName: '石油化工',
      nameObj: {
        PC: '石油化工',
        CA: '煤炭煤化工',
        PW: '电力',
        FF: '林果'
      }
    };
  },
  mounted() {
    // PC石油  CA煤炭 PW电力 FF林果业
    // this.flag = "PC";
  },
  computed: {
    industryData() {
      return this.$store.state.allData.industry;
    },
  },
  methods: {
    clickItem(e) {
      let data = this.$store.state.allData.industry;
      // if (data) {
        this.flag = e;
        this.allData = data;
        this.compontentDataST = data['institutionRank' + this.flag]
        this.compontentDataMD = data['mapData' + this.flag]
        this.compontentDataOP = data['patentOperate' + this.flag]
        let obj = {}
        obj['patentType' + this.flag] = data['patentType' + this.flag]
        obj['recently' + this.flag] = data['recently' + this.flag]
        obj['trend' + this.flag] = data['trend' + this.flag]
        obj.flag = this.flag
        this.compontentDataIN = obj
        this.titleName = this.nameObj[e]
      // }
    },
  },
  watch: {
    industryData: {
      handler: function (val) {
        if (val) {
          let data = JSON.parse(JSON.stringify(this.$store.state.allData.industry));
          this.allData = data;
          this.compontentDataST = data['institutionRank' + this.flag]
          this.compontentDataMD = data['mapData' + this.flag]
          this.compontentDataOP = data['patentOperate' + this.flag]
          let obj = {}
          obj['patentType' + this.flag] = data['patentType' + this.flag]
          obj['recently' + this.flag] = data['recently' + this.flag]
          obj['trend' + this.flag] = data['trend' + this.flag]
          obj.flag = this.flag
          this.compontentDataIN = obj
          let objALL = {}
          objALL.PC = data.mapALLPC
          objALL.CA = data.mapALLCA
          objALL.PW = data.mapALLPW
          objALL.FF = data.mapALLFF
          this.mapALL = objALL
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
.about {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: blanchedalmond; */
}
.top {
  flex: 65%;
  display: flex;
  justify-content: space-around;
}
.bottom {
  flex: 33%;
}
.left {
  width: 29%;
}
.middle {
  width: 40%;
}
.right {
  width: 29%;
  height: 100%;
}
</style>
