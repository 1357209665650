<template>
  <div id="compontent">
    <dv-border-box-10 width="85%" backgroundColor="RGBA(77, 144, 254,0.2)">
      <div
        style="
          height: 95%;
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div class="title">
          <span class="fixed-yellow">{{name}}</span>产业专利授权量机构排名
        </div>
        <div class="table">
          <dv-scroll-board :config="config" style="width: 100%; height: 90%" />
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
export default {
  props: ["data","name"],
  data() {
    return {
      allData: "",
      config: {},
    };
  },
  mounted() {},
  watch: {
    data: {
      handler: function (val) {
        if (val) {
          this.allData = val;
          let data = JSON.parse(JSON.stringify(this.allData));
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].length; j++) {
              if (i < 3) {
                let a =
                  "<span style='font-size:0.25rem;color:#fac858'>" +
                  data[i][j] +
                  "</span>";
                data[i][j] = a;
              } else {
                let a =
                  "<span style='font-size:0.2rem'>" + data[i][j] + "</span>";
                data[i][j] = a;
              }
            }
          }
          this.config = {
            header: ["排名", "机构名称", "专利授权量"],
            data: data,
            rowNum: 8,
            waitTime: 1000,
            headerHeight: 40,
            oddRowBGC: "RGBA(77, 144, 254,0.1)",
            evenRowBGC: "RGBA(10, 39, 50,0.2)",
            headerBGC: "RGBA(77, 144, 254,0.3)",
            align: ["center", "center", "center"],
          };
        }
      },
      deep: true,
      immediate: true
    },
    name: {
      handler: function (val) {
        // console.log(val)
      },
      immediate: true
    }
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.title {
  font-size: 0.25rem /* 20px -> .25rem */;
  line-height: 0.625rem /* 50px -> .625rem */;
  height: 10%;
}
.table {
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
</style>