<template>
    <div class="out">
            <div
            class="title"
        style="
          color: #fff;
          font-size: 0.38rem;
          margin-top: 0.2rem;
          font-weight: bolder;
        "
      >
        <div>新疆维吾尔自治区知识产权监测平台</div>
        <div>
          <dv-decoration-3 style="width: 6.2rem; height: 0.3rem; margin-top: 0.1rem"/>
        </div>
      </div>
        <dv-loading style="">
            <div style="font-weight: bold;font-size: 0.22rem; margin-top:0.15rem">
            加载数据中
            </div>
            </dv-loading>
    </div>
</template>
<script>
export default {
  mounted () {
    this.$emit('loading', true)
  }
}
</script>
<style scoped>
.out{
    position: relative;
}
.title{
    position: absolute;
    left: 50%;
    top: 2%;
    transform: translateX(-50%);
}
</style>
