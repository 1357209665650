<template>
  <div id="compontent">
    <dv-border-box-7 width="85%" backgroundColor="RGBA(77, 144, 254,0.2)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">{{ name }}</span
          >产业创新情况
        </div>
        <div class="content">
          <div class="left">
            <echart
              id="BarAA"
              :options="optionsLeft"
              :data="patentType"
              :isChange="true"
            />
          </div>
          <div>
            <dv-decoration-2
              :reverse="true"
              :dur="2"
              style="width: 0.0625rem; height: 50%; transform: rotateX(180deg)"
            />
            <dv-decoration-2
              :reverse="true"
              :dur="2"
              style="width: 0.0625rem; height: 50%"
            />
          </div>
          <div class="middle">
            <echart
              id="LineA"
              :data="recently"
              :options="optionsMiddle"
              :isChange="true"
            />
          </div>
          <div>
            <dv-decoration-2
              :reverse="true"
              :dur="2"
              style="width: 0.0625rem; height: 50%; transform: rotateX(180deg)"
            />
            <dv-decoration-2
              :reverse="true"
              :dur="2"
              style="width: 0.0625rem; height: 50%"
            />
          </div>
          <div class="right">
            <echart
              id="BarCC"
              :data="trend"
              :options="optionsRight"
              :isChange="true"
            />
          </div>
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import echart from "../../components/echarts/echart.vue";
export default {
  components: { echart },
  props: ["data", "flag", "name"],
  data() {
    return {
      allData: "",
      optionsLeft: "",
      optionsMiddle: "",
      optionsRight: "",
      patentType: "",
      recently: {},
      trend: "",
    };
  },
  mounted() {},
  methods: {
    setOption() {
      this.optionsLeft = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255,255,255,0.5)",
          textStyle: {
            color: "#FFF",
          },
          confine: true,
          formatter: function (res) {
            return (
              '<span style="font-size:0.175rem">' +
              res.data.name +
              "<br/>" +
              "数量：</span>" +
              '<span style="font-size:0.2rem;font-weight:bold">' +
              res.data.value +
              "</span>"
            );
          },
        },
        series: [
          {
            name: "数量",
            type: "pie",
            radius: ["50%", "80%"],
            // center: ['50%', '50%'],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: this.patentType,
            labelLine: {
              show: true,
              lineStyle: {
                width: 2,
              },
            },
            label: {
              //文本样式
              normal: {
                textStyle: {
                  fontSize: "0.2rem", // 改变标示文字的大小
                  color: "#f1f1fe",
                },
              },
            },
          },
        ],
      };
      this.optionsMiddle = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255,255,255,0.5)",
          textStyle: {
            color: "#FFF",
          },
          formatter: function (res) {
            return (
              '<div style="font-size:0.2rem">专利授权量</div>' +
              '<span style="font-size:0.175rem">' +
              res.name +
              "：</span>" +
              '<span style="font-size:0.2rem;font-weight:bold">' +
              res.data +
              "</span>"
            );
          },
        },
        xAxis: {
          type: "category",
          data: this.recently.yearArr,
          axisLabel: {
            fontSize: "0.15rem",
          },
        },
        yAxis: {
          nameTextStyle: {
            color: "#5c99c5",
            fontSize: "0.15rem",
          },
          name: "数量(件)",
          type: "value",
          axisLabel: {
            fontSize: "0.15rem",
          },
        },
        series: [
          {
            data: this.recently.valueArr,
            type: "line",
            lineStyle: {
              color: "rgba(145, 204, 117,1)",
            },
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: "0.125rem",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(160, 49, 108,0.5)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(220,56,129,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        grid: {
          top: 40,
          x: 70,
          x2: 45,
          y2: "30px",
        },
      };
      this.optionsRight = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.5)",
          textStyle: {
            color: "#FFF",
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          top: 40,
          x: 70,
          x2: 45,
          y2: 30,
        },
        xAxis: [
          {
            type: "category",
            data: this.trend.industryClassName,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              fontSize: "0.15rem",
              interval: 0,
            },
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "#5c99c5",
              fontSize: "0.15rem",
            },
            type: "value",
            name: "数量(件)",
            axisLabel: {
              fontSize: "0.15rem",
            },
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            data: this.trend.industryClassValue,
          },
        ],
      };
    },
  },
  watch: {
    data: {
      handler: function (val) {
        if (val) {
          this.allData = val;
          let { flag } = val;
          this.patentType = val["patentType" + flag];
          let recently = val["recently" + flag];
          let yearArr = []
          let valueArr = []
          for (let i = 0; i < recently.length; i++) {
            yearArr.push(recently[i].year + '年')
            valueArr.push(recently[i].data)
          }
          this.recently.all = recently
          this.recently.yearArr = yearArr
          this.recently.valueArr = valueArr
          this.trend = val["trend" + flag];
          this.$nextTick(() => {
            this.setOption();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 95%;
}
.out {
  height: 95%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.3rem /* 24px -> .3rem */;
  font-weight: bold;
}
.content {
  height: 88%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.left {
  width: 30%;
  height: 100%;
}
.middle {
  width: 35%;
  height: 100%;
}
.right {
  width: 30%;
  height: 100%;
}
</style>