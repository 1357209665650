<template>
  <div>
    <div class="top" style="margin-top: 1%; justify-content: space-between">
      <div class="top">
        <dv-decoration-10 style="width: 4rem; height: 0.625rem" />
        <dv-decoration-8 style="width: 3rem; height: 0.55rem" />
        <div id="leftTop">
          <div
            class="linkdiv"
            @click="clickPath(0)"
            :class="{ choosen: ifChoosen == 'Total' }"
          >
            <router-link to="/total" tag="span">区域总览</router-link>
          </div>
          <div
            class="linkdiv"
            @click="clickPath(1)"
            :class="{ choosen: ifChoosen == 'Industry' }"
          >
            <router-link to="/industry" tag="span">特色产业</router-link>
          </div>
          <div
            class="linkdiv"
            @click="clickPath(2)"
            :class="{ choosen: ifChoosen == 'Board' }"
          >
            <router-link to="/board" tag="span">园区看板</router-link>
          </div>
          <div
            class="linkdiv"
            @click="clickPath(3)"
            :class="{ choosen: ifChoosen == 'Patent' }"
          >
            <router-link to="/patent" tag="span">专利运营</router-link>
          </div>
          <div
            class="linkdiv"
            @click="clickPath(4)"
            :class="{ choosen: ifChoosen == 'Innovate' }"
          >
            <router-link to="/innovate" tag="span">创新主体</router-link>
          </div>
        </div>
      </div>
      <div
        style="
          color: #fff;
          font-size: 0.38rem;
          margin-top: 0.2rem;
          font-weight: bolder;
        "
      >
        <div>新疆维吾尔自治区知识产权监测平台</div>
        <div>
          <dv-decoration-3
            style="width: 6.2rem; height: 0.3rem; margin-top: 0.1rem"
          />
        </div>
      </div>
      <div class="top">
        <dv-decoration-8 :reverse="true" style="width: 3rem; height: 0.55rem" />
        <dv-decoration-10
          style="width: 4rem; height: 5px; transform: rotateY(180deg)"
        />
        <div id="right-top">数据更新于：{{time}}</div>
        <div class="controlOut">
          <div :class="playORpause" @click="changePlay">
            <div class="elm topa"></div>
            <div class="elm righta"></div>
            <div class="elm bottoma"></div>
            <div class="elm lefta"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "top",
  props: {
    reqOver: {
      default: false,
      type: Boolean,
    },
    time: {
      default: '暂无数据',
      type: String
    }
  },
  data() {
    return {
      ifChoosen: "Total",
      playORpause: "pausea",
      flag: 0,
      intervalId: "",
      pathArray: ["/total", "/industry", "/board", "/patent", "/innovate"],
      choosenArray: ["Total", "Industry", "Board", "Patent", "Innovate"],
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.ifChoosen = val.name;
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
    reqOver: {
      handler: function (val) {
        this.flag = 0
        this.interval()
      },
      immediate: true
    }
  },
  mounted() {
    // this.intervalId = setInterval(()=>{
    //   if (this.flag ==5) {
    //     this.flag = 0
    //   }
    //   let flag = this.flag
    //   this.ifChoosen = this.choosenArray[this.flag]
    //   this.$router.push({path:this.pathArray[this.flag]})
    //   this.flag += 1
    // },60000)
  },
  methods: {
    changePlay() {
      if (this.playORpause === "pausea") {
        this.playORpause = "playa";
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId)
        this.interval()
        this.playORpause = "pausea";
      }
    },
    clickPath(e) {
      clearInterval(this.intervalId);
      this.flag = e;
      if (this.playORpause === 'pausea'){
      this.interval()
      }
    },
    interval () {
      clearInterval(this.intervalId)
      this.intervalId = setInterval(()=>{
        let flag = this.flag
        flag++
        if (flag >= 5) {
          flag = 0
        }
        this.$router.push({path: this.pathArray[flag]})
        this.flag = flag
      }, 60000)
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
};
</script>
<style>
.top {
  display: flex;
  position: relative;
}
#leftTop {
  width: 100%;
  position: absolute;
  top: 0.8rem;
  left: 0.15rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 0.22rem;
  color: rgba(255, 255, 255, 0.6);
}
.linkdiv {
  padding: 0.09rem /* 6px -> .075rem */;
  text-align: center;
  margin: 0 0.1rem;
}
.linkdiv {
  cursor: pointer;
  flex: 1;
  background: url("../../public/imgs/link_other_default.png") no-repeat;
  background-size: 100% 100%;
}
.linkdiv:hover {
  background: url("../../public/imgs/link_other_hover.png") no-repeat;
  background-size: 100% 100%;
}
.choosen {
  color: white;
  font-weight: bold;
  background: url("../../public/imgs/link_other_hover.png") no-repeat;
  background-size: 100% 100%;
}
#right-top {
  width: 100%;
  position: absolute;
  top: 0.8rem;
  font-size: 0.3rem;
  color: aliceblue;
  text-align: left;
}

.controlOut {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-20%);
  cursor: pointer;
}

.stopa,
.playa,
.pausea {
  width: 0.875rem;
  height: 0.875rem;
  position: relative;
  transition: 0.2s;
}
.elm {
  border-radius: 0.07rem /* 5.6px -> .07rem */;
  background: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
}
.topa {
  width: 0.875rem;
  height: 0.14rem /* 11.2px -> .14rem */;
  top: 0;
  left: 0;
}
.righta {
  width: 0.14rem /* 11.2px -> .14rem */;
  height: 0.875rem;
  top: 0;
  left: 0.735rem /* 58.8px -> .735rem */;
}
.bottoma {
  width: 0.875rem;
  height: 0.14rem /* 11.2px -> .14rem */;
  top: 0.735rem /* 58.8px -> .735rem */;
  left: 0;
}
.lefta {
  width: 0.14rem /* 11.2px -> .14rem */;
  height: 0.875rem;
  top: 0;
  left: 0;
}
.playa {
  transform: rotate(-90deg);
}
.playa .righta {
  transform-origin: center top;
  transform: rotate(29deg) translate(4px, 2px) scaleY(1);
}
.playa .bottoma {
  display: none;
}
.playa .lefta {
  transform-origin: center top;
  transform: rotate(-29deg) translate(-4px, 2px) scaleY(1);
}
.pausea {
  transform: rotate(180deg);
}
.pausea .topa {
  opacity: 0;
}
.pausea .righta {
  transform: translateX(-0.14rem /* 11.2px -> .14rem */) scaleY(0.8);
}
.pausea .bottoma {
  opacity: 0;
}
.pausea .lefta {
  transform: translateX(0.14rem /* 11.2px -> .14rem */) scaleY(0.8);
}
</style>