import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import * as totalData from '../../public/jsData/total'
import * as industryData from '../../public/jsData/industry'

export default new Vuex.Store({
  state: {
    totalData,
    industryData,
    allData: ''
  },
  mutations: {
    changeData(state, data){
      this.state.allData = data
    }
  },
  actions: {
  },
  modules: {
  }
})
