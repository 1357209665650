import Vue from 'vue'
import VueRouter from 'vue-router'
import Total from '../views/total.vue'
import Board from '../views/board.vue'
import Industry from '../views/industry.vue'
import Innovate from '../views/innovate.vue'
import Patent from '../views/patent.vue'
import Loading from '../views/loading.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: Loading
  },
  {
    path: '/total',
    name: 'Total',
    component: Total
  },
  {
    path: '/patent',
    name: 'Patent',
    component: Patent
  },
  {
    path: '/board',
    name: 'Board',
    component: Board
  },
  {
    path: '/industry',
    name: 'Industry',
    component: Industry
  },
  {
    path: '/innovate',
    name: 'Innovate',
    component: Innovate
  }
]

const router = new VueRouter({
  routes
})

export default router
