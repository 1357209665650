<template>
  <div class="about">
    <div class="left">
      <div class="leftTable">
        <left-table :isHaveData='flag' />
      </div>
      <div class="leftPie">
        <left-pie :isHaveData='flag' />
      </div>
    </div>
    <div class="middle">
      <div class="topMap">
      <map-view :isHaveData='flag' />
      </div>
      <div class="bottomLine">
        <middle-line :isHaveData='flag' />
      </div>
    </div>
    <div class="right">
      <div class="rightTable">
        <right-table :isHaveData='flag' />
      </div>
      <div class="rightPie">
        <right-pie :isHaveData='flag' />
      </div>
    </div>
  </div>
</template>
<script>
import LeftPie from './patent/leftPie.vue'
import leftTable from './patent/leftTable.vue'
import MapView from './patent/mapView.vue'
import MiddleLine from './patent/middleLine.vue'
import RightPie from './patent/rightPie.vue'
import RightTable from './patent/rightTable.vue'
export default {
  components: { leftTable, LeftPie, MapView, MiddleLine, RightTable, RightPie },
  data () {
    return {
      flag: 0
    }
  },
  computed: {
    patentData() {
      return this.$store.state.allData.patent;
    },
  },
  watch: {
    patentData: {
      handler: function (val) {
        if (val) {
          this.flag++
        }
      },
      immediate: true
    }
  }
}
</script>
<style scoped>
.about{
  display: flex;
  justify-content: space-around;
}
.left{
  width: 29%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.leftTable{
  /* background-color: blueviolet; */
  height: 48%;
}
.leftPie{
  /* background-color: brown; */
  height: 48%;
}
.right{
  width: 29%;
    display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rightTable{
  height: 48%;
}
.rightPie{
  height: 48%;
}
.middle{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.topMap{
  height: 60%;
}
.bottomLine{
  height: 38%;
}
</style>
