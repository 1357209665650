<template>
  <div id="compontent">
    <dv-border-box-7 backgroundColor="rgba(93, 93, 95,0.3)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">运营</span>活动活跃机构TOP30
        </div>
        <div class="content">
          <dv-scroll-ranking-board
            :config="config"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
export default {
  props: ["isHaveData"],
  data() {
    return {
      config: {},
      allData: "",
    };
  },
  mounted() {
  },
  methods: {
    setConfig() {
          let data = JSON.parse(JSON.stringify(this.allData));
          let dataConfig = [];
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            dataConfig.push({
              name:
                '<span style="font-size:0.175rem;line-height:0.125rem">' +
                item[0] +
                "</span>",
              value: item[1],
            });
          }
          this.config = {
            data: dataConfig,
            unit: "次",
            sort: true,
            //   carousel:'page'
          };
    },
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        if (val) {
          this.allData = this.$store.state.allData.patent.patentTop30;
        }
      },
      immediate: true,
    },
    allData: {
      handler: function (val) {
        if (val) {
          this.setConfig()
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 10%;
  font-size: 0.225rem /* 18px -> .225rem */;
  font-weight: bold;
  line-height: 0.5rem /* 40px -> .5rem */;
}
.content {
  height: 88%;
}
</style>