<template>
  <div id="app">
    <dv-full-screen-container>
    <top id="header" v-if="!isLoading" :reqOver='!isLoading' :time='time'></top>
    <router-view id="routerView" />
    </dv-full-screen-container>
  </div>
</template>
<script>
import top from './views/top.vue'
export default {
  components: { top },
  data () {
    return {
      isLoading: true,
      time: '暂无数据',
      timeInterval: ''
    }
  },
  mounted () {
    this.getTime()
    this.setTimeInterval()
    this.isLoading = true
        if (this.$route.name !== 'loading'){
          this.$router.push({name: 'loading'})
        }
    this.$axios.get('/all')
    .then(res => {
      if (res.data.status === 0) {
        this.$store.commit('changeData', res.data.data)
                setTimeout(() => {
                   this.isLoading = false
                if (this.$route.name !== 'Total'){
                  this.$router.push({name: 'Total'})
                }
                }, 3000)
      }
    })
  },
  methods: {
    loading () {
      console.log(1)
    },
    getTime () {
      let data = {
        time: '暂无数据',
        ok: false
      }
      this.$axios.get('/getTime')
        .then(res => {
          if (res.data.status === 0) {
            this.time = res.data.time
          } else {
            alert('请求出现问题，请联系管理员或刷新')
          }
        })
    },
    setTimeInterval () {
      clearInterval(this.timeInterval)
      this.timeInterval = setInterval(() => {
        this.$axios.get('/getTime')
          .then(res => {
            if (res.data.status === 0) {
              this.time = res.data.time
            } else {
              alert('请求出现问题，请联系管理员或刷新')
            }
          })
      }, 21600000)
    }
  },
  watch: {
    time: {
      handler: function (val, old) {
        if (old !== '暂无数据') {
              this.isLoading = true
        if (this.$route.name !== 'loading'){
          this.$router.push({name: 'loading'})
        }
          this.$axios.get('/all')
            .then(res => {
              if (res.data.status === 0) {
                this.$store.commit('changeData', res.data.data)
                setTimeout(() => {
                   this.isLoading = false
                if (this.$route.name !== 'Total'){
                  this.$router.push({name: 'Total'})
                }
                }, 3000)
              }
            })
        }
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timeInterval)
  }
}
</script>
<style>
#app{
  background-image: url('../public/imgs/background.png');
  height: 100%;
}
#header{
  height: 11%;
}
#routerView{
  /* background-color: aqua; */
  height: 89%;
  color: aliceblue;
}
</style>
