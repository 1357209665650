<template>
  <div id="compontent">
    <dv-border-box-1
      width="90%"
      height="90%"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div
        style="
          width: 90%;
          height: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div id="top">
          <div class="title">
            <span class="fixed-yellow">授权</span>专利领域分布情况
          </div>
          <div class="table child">
            <dv-scroll-board
              :config="configA"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div id="bottom">
          <div class="title">
            <span class="fixed-yellow">专利</span>授权量机构排名
          </div>
          <div class="table child">
            <dv-scroll-board
              :config="configB"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>
<script>
export default {
  props: ["data", "isHaveData"],
  data() {
    return {
      configA: {},
      configB: {},
      allData: {
        A: "",
        B: "",
      },
    };
  },
  mounted() {},
  watch: {
    isHaveData: {
      handler: function (val) {
        if (val) {
          // 领域分布
          this.allData.A = this.$store.state.allData.board.authFieldRank;
          // 授权量
          this.allData.B = this.$store.state.allData.board.authAmountRank;
        }
      },
      immediate: true,
    },
    allData: {
      handler: function (val) {
        if (val.A) {
          let dataA = this.allData.A;
          let dataB = this.allData.B;

          let dataA_config = JSON.parse(JSON.stringify(dataA));
          let dataB_config = JSON.parse(JSON.stringify(dataB));
          for (let i = 0; i < dataA_config.length; i++) {
            for (let j = 0; j < dataA_config[i].length; j++) {
              if (i < 3) {
                let a =
                  "<span style='font-size:0.25rem;color:#fac858'>" +
                  dataA_config[i][j] +
                  "</span>";
                dataA_config[i][j] = a;
              } else {
                let a =
                  "<span style='font-size:0.2rem'>" +
                  dataA_config[i][j] +
                  "</span>";
                dataA_config[i][j] = a;
              }
            }
          }
          this.configA = {
            data: dataA_config,
            header: [
              "<span style='font-size:0.2rem'>排名</span>",
              "<span style='font-size:0.2rem'>IPC号</span>",
              "<span style='font-size:0.2rem'>专利授权量</span>",
            ],
            waitTime: 1000,
            headerHeight: 40,
            oddRowBGC: "RGBA(77, 144, 254,0.1)",
            evenRowBGC: "RGBA(10, 39, 50,0.2)",
            headerBGC: "RGBA(77, 144, 254,0.4)",
            align: ["center", "center", "center"],
          };
          for (let i = 0; i < dataB_config.length; i++) {
            for (let j = 0; j < dataB_config[i].length; j++) {
              if (i < 3) {
                let a =
                  "<span style='font-size:0.25rem;color:#fac858'>" +
                  dataB_config[i][j] +
                  "</span>";
                dataB_config[i][j] = a;
              } else {
                let a =
                  "<span style='font-size:0.2rem'>" +
                  dataB_config[i][j] +
                  "</span>";
                dataB_config[i][j] = a;
              }
            }
          }
          this.configB = {
            data: dataB_config,
            header: [
              "<span style='font-size:0.2rem'>排名</span>",
              "<span style='font-size:0.2rem'>机构名称</span>",
              "<span style='font-size:0.2rem'>专利授权量</span>",
            ],
            waitTime: 1000,
            headerHeight: 40,
            oddRowBGC: "RGBA(77, 144, 254,0.1)",
            evenRowBGC: "RGBA(10, 39, 50,0.2)",
            headerBGC: "RGBA(77, 144, 254,0.4)",
            align: ["center", "center", "center"],
          };
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
}

#top {
  height: 48%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#bottom {
  height: 48%;
  width: 100%;
  /* background-color: brown; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  height: 10%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
  line-height: 0.625rem /* 50px -> .625rem */;
}

.child {
  height: 85%;
  width: 100%;
}
</style>
