<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div class="out">
        <div class="item title">
          <span class="fixed-yellow">专利</span>申请类型分布
        </div>
        <div class="item content">
          <echart :options="options" :data="allData" id="pieB" @clickToSearch='clickChart' :isChange="true" />
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  components: { Echart },
  name: "pieChartClass",
  props: ['isHaveData'],
  data() {
    return {
      data: [
        { value: 31655, name: "发明" },
        { value: 16204, name: "外观" },
        { value: 73056, name: "新型" },
      ],
      options: "",
      allData: ''
    };
  },
  mounted() {
    if (this.$store.state.allData.total) {
      this.allData = this.$store.state.allData.total.patentClass
    }
  },
  methods: {
    clickChart (e) {
      let {data} = e
      let type = ''
      if (data.name === '发明') type = 'type: 发明公开 OR type: 发明授权'
      else if (data.name === '外观') type = 'type: 外观设计'
      else if (data.name === '新型') type = 'type: 实用新型'
      let condition = `(${type}) AND (province:新疆维吾尔自治区)`
      window.open('https://r.xjip.info/#/search?links=' + condition)
    },
    setOption() {
      this.options = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255,255,255,0.5)",
          textStyle: {
            color: "#FFF",
          },
          confine:true,
           formatter:function(res){
          return '<span style="font-size:0.175rem">'+res.data.name+'<br/>'+'数量：</span>'+'<span style="font-size:0.2rem;font-weight:bold">'+res.data.value+'</span>'
        }
        },
        series: [
          {
            name: "数量",
            type: "pie",
            radius: ["50%", "80%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: this.allData,
            labelLine: {
              show: true,
              lineStyle: {
                width: 2,
              },
            },
            label: {
              //文本样式
              normal: {
                textStyle: {
                  fontSize: "0.2rem", // 改变标示文字的大小
                  color: "#f1f1fe",
                },
              },
            },
          },
        ],
      }
    }
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        this.allData = this.$store.state.allData.total.patentClass
      }
    },
    allData: {
      handler: function (val) {
        this.setOption()
        // console.log(this.allData)
      }
    }
  }
}
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
</style>