
var tableItems = [
    ["01","石河子大学",3613],
    ["02","新疆八一钢铁股份有限公司",2466],
    ['03',"塔里木大学",2429],
    ['04',"新疆大学",2341],
    ['05',"中国石油集团西部钻探工程有限公司",1948],
    ['06',"中国科学院新疆理化技术研究所",1645],
    ['07',"宝钢集团新疆八一钢铁有限公司",1619],
    ['08',"国家电网公司",1529],
    ['09',"美克国际家居用品股份有限公司",1514],
    ['10',"国家电网有限公司",1323],
    [11,"新疆农业大学",1295],
    [12,"新疆金风科技股份有限公司",1203],
    [13,"新疆农垦科学院",1056],
    [14,"新疆维吾尔自治区人民医院",898],
    [15,"新疆天业（集团）有限公司",777],
    [16,"中国科学院新疆生态与地理研究所",776],
    [17,"新疆医科大学第一附属医院",698],
    [18,"新疆中泰化学股份有限公司",572],
    [19,"新疆工程学院",519],
    [20,"新特能源股份有限公司",483],
    ]
    
    
    //右侧授权量
    var displayname =	 ['商标注册量','专利授权量'];
    var displayvalue1 =	['0','24668','32802','33323','35218'];
    var displayvalue2 =	['7268','8001','9287','8066','10801'];
    var yearValue =	['2016年','2017年','2018年','2019年','2020年'];
    
    //右侧专利类型饼图
     var zllxdata =	['发明','新型','外观'];
     var zllxdata1 =	['31655','73056','16204'];
     var zllxPer =	['26.5%','59.5%','14%'];
    
    
    
    //右侧国民经济分类饼图
     var gmjjdata =	['农、林、牧、渔业','采矿业','制造业','居民服务、修理和其他服务业'];
     var gmjjdata1 =['7522','4813','111888','17639'];
     var gmjjdataWG =	['农副食品加工业','酒、饮料和精制茶制造业','烟草制品业'];
     var gmjjdataWGValue =	['4375','3810','43'];
    
    
    
    //左下角图使用数据
    var serierName = ['许可','转让','质押']	
    var left1Value = 	['1066','10360','318'];
    var left1ValuePer = 	['9.1%','88.2%','2.7%'];
    
    
    //右侧第二行第二个柱图
    var leftname = 	['企业','机关团体','大专院校','科研机构','个人']
    var leftvalue = 	['56008','1159','11867','8217','33653']
    
    
    
    //以下为专利数据
    //地图数据-专利	
    
     var mapData = [	{name:"乌鲁木齐市",value : [87.617733,43.792818,62113]},
        {name:"克拉玛依市",value : [84.873946,45.595886,8444]},
        {name:"吐鲁番市",value : [89.184078,42.947613,1996]},
        {name:"哈密市",value : [93.51316,42.833248,2684]},
        {name:"昌吉回族自治州",value : [87.304012,44.014577,11506]},
        {name:"博尔塔拉蒙古自治州",value : [82.074778,44.903258,2367]},
        {name:"巴音郭楞蒙古自治州",value : [86.150969,41.768552,5602]},
        {name:"阿克苏地区",value : [80.265068,41.170712,4042]},
        {name:"克孜勒苏柯尔克孜自治州",value : [76.172825,39.713431,457]},
        {name:"喀什地区",value : [75.989138,39.467664,3081]},
        {name:"和田地区",value : [79.92533,37.110687,1237]},
        {name:"伊犁哈萨克自治州",value : [81.317946,43.92186,7307]},
        {name:"塔城地区",value : [82.985732,46.746301,2671]},
        {name:"阿勒泰地区",value : [88.13963,47.848393,2575]},
        {name:"石河子市",value : [86.041075,44.305886,7504]},
        {name:"阿拉尔市",value : [81.285884,40.541914,1958]},
        {name:"图木舒克市",value : [79.077978,39.867316,59]},
        {name:"五家渠市",value : [87.526884,44.167401,646]},
        {name:"北屯市",value : [87.824932,47.353177,132]},
        {name:"铁门关市",value : [85.501218,41.827251,0]},
        {name:"双河市",value : [82.353656,44.840524,5]},
        {name:"可克达拉市",value : [80.63579,43.6832,1]},
        {name:"昆玉市",value : [79.287372,37.207994,0]},
        {name:"胡杨河市",value : [84.8275959,44.69288853,0]},
        ]
    
    // let mapData=[
    //     {name:'天山区',value:[1,1,4050]},
    //     {name:"沙依巴克区",value:[1,1,4100]},
    //     {name:"水磨沟区",value:[1,1,1441]},
    //     {name:"新市区",value:[1,1,11650]},
    //     {name:"头屯河区",value:[1,1,2250]},
    //     {name:"米东区",value:[1,1,1250]},
    //     {name:'达坂城区',value:[1,1,183]},
    //     {name:"乌鲁木齐县",value:[1,1,225]}
    // ]
    
        
    //地图数据-商标	
     var mapDataSB = 
       [{name:"乌鲁木齐市",value :90024},
        {name:"克拉玛依市",value : 4378},
        {name:"吐鲁番市",value : 3292},
        {name:"哈密市",value :3939},
        {name:"昌吉回族自治州",value : 8596},
        {name:"博尔塔拉蒙古自治州",value : 2045},
        {name:"巴音郭楞蒙古自治州",value : 7833},
        {name:"阿克苏地区",value : 10220},
        {name:"克孜勒苏柯尔克孜自治州",value : 13},
        {name:"喀什地区",value : 13190},
        {name:"和田地区",value : 11228},
        {name:"伊犁哈萨克自治州",value : 11728},
        {name:"塔城地区",value : 3625},
        {name:"阿勒泰地区",value : 3161},
        {name:"石河子市",value : 4596},
        {name:"阿拉尔市",value : 1903},
        {name:"图木舒克市",value : 928},
        {name:"五家渠市",value : 865},
        {name:"北屯市",value : 457},
        {name:"铁门关市",value : 538},
        {name:"双河市",value : 326},
        {name:"可克达拉市",value : 445},
        {name:"昆玉市",value : 81},
        {name:"胡杨河市",value : 0},
        ]
    
        // let mapDataSB=[
        //     {name:'新市区',value:[1,1,15035]},
        //     {name:"天山区",value:[1,1,48901]},
        //     {name:"沙依巴克区",value:[1,1,24024]},
        //     {name:"水磨沟区",value:[1,1,10853]},
        //     {name:"达坂城区",value:[1,1,622]},
        //     {name:"米东区",value:[1,1,6909]},
        //     {name:"乌鲁木齐县",value:[1,1,2172]},
        //     {name:"头屯河区",value:[1,1,3588]}
        // ]
    
    //地图数据-地标
     var mapDataDB = [	{name:"乌鲁木齐市",value : [87.617733,43.792818,3]},
        {name:"克拉玛依市",value : [84.873946,45.595886,0]},
        {name:"吐鲁番市",value : [89.184078,42.947613,9]},
        {name:"哈密市",value : [93.51316,42.833248,9]},
        {name:"昌吉回族自治州",value : [87.304012,44.014577,34]},
        {name:"博尔塔拉蒙古自治州",value : [82.074778,44.903258,3]},
        {name:"巴音郭楞蒙古自治州",value : [86.150969,41.768552,19]},
        {name:"阿克苏地区",value : [80.265068,41.170712,25]},
        {name:"克孜勒苏柯尔克孜自治州",value : [76.172825,39.713431,6]},
        {name:"喀什地区",value : [75.989138,39.467664,22]},
        {name:"和田地区",value : [79.92533,37.110687,33]},
        {name:"伊犁哈萨克自治州",value : [81.317946,43.92186,26]},
        {name:"塔城地区",value : [82.985732,46.746301,6]},
        {name:"阿勒泰地区",value : [88.13963,47.848393,9]},
        {name:"石河子市",value : [86.041075,44.305886,0]},
        {name:"阿拉尔市",value : [81.285884,40.541914,0]},
        {name:"图木舒克市",value : [79.077978,39.867316,0]},
        {name:"五家渠市",value : [87.526884,44.167401,1]},
        {name:"北屯市",value : [87.824932,47.353177,0]},
        {name:"铁门关市",value : [85.501218,41.827251,0]},
        {name:"双河市",value : [82.353656,44.840524,0]},
        {name:"可克达拉市",value : [80.63579,43.6832,0]},
        {name:"昆玉市",value : [79.287372,37.207994,0]},
        {name:"胡杨河市",value : [84.8275959,44.69288853,0]},
        ]
    
    
    //地图数据-电路	
     var mapDataDL = [	{name:"乌鲁木齐市",value : [87.617733,43.792818,2]},
        {name:"克拉玛依市",value : [84.873946,45.595886,1]},
        {name:"吐鲁番市",value : [89.184078,42.947613,0]},
        {name:"哈密市",value : [93.51316,42.833248,0]},
        {name:"昌吉回族自治州",value : [87.304012,44.014577,1]},
        {name:"博尔塔拉蒙古自治州",value : [82.074778,44.903258,0]},
        {name:"巴音郭楞蒙古自治州",value : [86.150969,41.768552,0]},
        {name:"阿克苏地区",value : [80.265068,41.170712,0]},
        {name:"克孜勒苏柯尔克孜自治州",value : [76.172825,39.713431,0]},
        {name:"喀什地区",value : [75.989138,39.467664,0]},
        {name:"和田地区",value : [79.92533,37.110687,0]},
        {name:"伊犁哈萨克自治州",value : [81.317946,43.92186,3]},
        {name:"塔城地区",value : [82.985732,46.746301,0]},
        {name:"阿勒泰地区",value : [88.13963,47.848393,0]},
        {name:"石河子市",value : [86.041075,44.305886,0]},
        {name:"阿拉尔市",value : [81.285884,40.541914,0]},
        {name:"图木舒克市",value : [79.077978,39.867316,0]},
        {name:"五家渠市",value : [87.526884,44.167401,0]},
        {name:"北屯市",value : [87.824932,47.353177,0]},
        {name:"铁门关市",value : [85.501218,41.827251,0]},
        {name:"双河市",value : [82.353656,44.840524,0]},
        {name:"可克达拉市",value : [80.63579,43.6832,0]},
        {name:"昆玉市",value : [79.287372,37.207994,0]},
        {name:"胡杨河市",value : [84.8275959,44.69288853,0]},
        ]

    
    
    export default{
        tableItems,
        mapDataDL,
        mapDataDB,
        mapDataSB,
        mapData,
        leftname,
        leftvalue,
        displayname,
        displayvalue1,
        displayvalue2,
        yearValue,
        zllxPer,
        zllxdata,
        zllxdata1,
        gmjjdata,gmjjdata1,
        gmjjdataWG,
        gmjjdataWGValue
    }
   