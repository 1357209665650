var tableItems1_1 = [
    ["01","北京市","1058"],
    ["02","未知","591"],
    ["03","浙江省","292"],
    ["04","广东省","251"],
    ["05","江苏省","232"],
    ["06","上海市","106"],
    ["07","福建省","100"],
    ["08","四川省","93"],
    ["09","山东省","85"],
    ["10","安徽省","69"],
    ["11","河南省","62"],
    ["12","新疆维吾尔自治区","56"],
    ["13","河北省","38"],
    ["14","陕西省","35"],
    ["15","湖北省","28"],
    ["15","甘肃省","28"],
    ["15","辽宁省","28"],
    ["18","重庆市","23"],
    ["19","湖南省","22"],
    ["19","江西省","22"],
    ["21","天津市","21"],
    ["22","山西省","19"],
    ["23","黑龙江省","18"],
    ["24","广西壮族自治区","14"],
    ["25","内蒙古自治区","13"],
    ["26","吉林省","11"],
    ["26","青海省","11"],
    ["28","贵州省","10"],
    ["29","云南省","5"],
    ["29","海南省","5"],
    ["31","西藏自治区","4"],
    ["32","宁夏回族自治区","3"],
    ]
    
    
    var tableItems1_2 = [
    ["01","北京市","1732"],
    ["02","浙江省","375"],
    ["03","广东省","354"],
    ["04","江苏省","293"],
    ["05","四川省","243"],
    ["06","山东省","196"],
    ["07","福建省","144"],
    ["08","上海市","119"],
    ["09","湖南省","108"],
    ["10","河南省","100"],
    ["11","重庆市","99"],
    ["12","未知","91"],
    ["13","安徽省","89"],
    ["14","天津市","81"],
    ["15","湖北省","73"],
    ["15","河北省","73"],
    ["17","陕西省","70"],
    ["18","新疆维吾尔自治区","62"],
    ["19","辽宁省","49"],
    ["20","黑龙江省","48"],
    ["21","山西省","35"],
    ["22","江西省","34"],
    ["23","广西壮族自治区","27"],
    ["24","内蒙古自治区","24"],
    ["25","甘肃省","23"],
    ["26","宁夏回族自治区","18"],
    ["27","吉林省","14"],
    ["28","云南省","6"],
    ["29","贵州省","5"],
    ["30","台湾省","4"],
    ["31","青海省","3"],
    ["32","海南省","1"],
    ]
    
    
    var tableItems1_3 = [
    ["01","乌鲁木齐市","2448"],
    ["02","克拉玛依市","534"],
    ["03","昌吉回族自治州","510"],
    ["04","石河子市","365"],
    ["05","伊犁哈萨克自治州","139"],
    ["06","巴音郭楞蒙古自治州","120"],
    ["07","哈密地区","110"],
    ["08","吐鲁番地区","109"],
    ["09","塔城地区","94"],
    ["10","喀什地区","73"],
    ["11","阿克苏地区","39"],
    ["11","阿勒泰地区","39"],
    ["13","博尔塔拉蒙古自治州","37"],
    ["14","和田地区","23"],
    ["15","阿拉尔市","19"],
    ["16","五家渠市","17"],
    ["17","克孜勒苏柯尔克孜自治州","4"],
    ["17","图木舒克市","4"],
    ]
    
    
    
    var tableItems2 = [
    ["美克国际家居用品股份有限公司",1386],
    ["中国石油集团西部钻探工程有限公司",626],
    ["乌鲁木齐九品芝麻信息科技有限公司",329],
    ["新疆八一钢铁股份有限公司",270],
    ["宝钢集团新疆八一钢铁有限公司",249],
    ["西部钻探克拉玛依钻井工艺研究院",238],
    ["新疆石油管理局钻井工艺研究院",224],
    ["国网新疆电力公司",210],
    ["国网新疆电力有限公司",157],
    ["新疆万佳万康食品科技有限公司",156],
    ["新疆医科大学",143],
    ["国家电网公司",130],
    ["新疆蓝山屯河化工有限公司",127],
    ["新疆天业(集团)有限公司",99],
    ["新疆中泰化学股份有限公司",90],
    ["天能化工有限公司","72"],
    ["新疆通奥油田技术服务有限公司",63],
    ["新特能源股份有限公司",63],
    ["国家电网有限公司",62],
    ["新疆电力公司电力科学研究院",58],
    ["新疆金风科技股份有限公司",58],
    ["国网新疆电力有限公司电力科学研究院",58],
    ["新疆医科大学第一附属医院",57],
    ["石河子大学",57],
    ["新疆机械研究院股份有限公司",57],
    ["新疆天业（集团）有限公司",57],
    ["中国科学院新疆理化技术研究所",55],
    ["新疆广汇中化能源技术开发有限公司",54],
    ["新疆广汇煤炭清洁炼化有限责任公司",54],
    ["克拉玛依市建业能源股份有限公司",53],
    ]
    
    
     //中23国民经济分类饼图
     let gmjjdata=[
         {name:'A 农、林、牧、渔业',value:"110"},
         {name:"B 采矿业",value:"69"},
         {name:"C 制造业",value:"5172"},
         {name:"D 电力、热力、燃气及水生产和供应业",value:"295"},
         {name:"E 建筑业",value:"313"},
         {name:"I 信息传输、软件和信息技术服务业",value:"44"},
         {name:"O 居民服务、修理和其他服务业",value:"194"}
     ]
     let gmjjdataWG=[
        {name:"13 农副食品加工业",value:"12"},
        {name:"17 纺织业",value:"58"},
        {name:"19 皮革、毛皮、羽毛及其制品和制鞋业",value:"70"},
        {name:"21 家具制造业",value:"1340"},
        {name:"23 印刷和记录媒介复制业",value:"12"},
        {name:"24 文教、工美、体育和娱乐用品制造业",value:"15"},
        {name:"29 橡胶和塑料制品业",value:"193"},
        {name:"30 非金属矿物制品业",value:"1339"},
        {name:"31 黑色金属冶炼和压延加工业",value:"33"},
        {name:"33 金属制品业",value:"447"},
        {name:"34 通用设备制造业",value:"101"},
        {name:"35 专用设备制造业",value:"79"},
        {name:"36 汽车制造业",value:"8"},
        {name:"37 铁路、船舶、航空航天和其他运输设备制造业",value:"8"},
        {name:"38 电气机械和器材制造业",value:"84"},
        {name:"39 计算机、通信和其他电子设备制造业",value:"47"},
        {name:"40 仪器仪表制造业",value:"1"},
     ]
    
    //中1的运营趋势
    var displayname =	 ['许可','质押','转让'];
    var displayvalue1 =	[31, 28, 6, 54, 7]
    var displayvalue2 =	[22, 12, 46, 108, 38]
    var displayvalue3 =	[535, 1059, 2534, 1335, 1608]
    var yearValue =	[2016, 2017, 2018, 2019, 2020]
    
     //加载固定数据
    var data1 = 	11744
    var data2 = 	1066
    var data3 = 	10360
    var data4 = 	318
    //中间地图展示
    
    var mapData = [	
        { name: "黑龙江省", value: [7,13,0,20] },
        { name: "广东省", value: [34,219,0,253] },
        { name: "西藏自治区", value: [0,4,0,4] },
        { name: "河北省", value: [7,32,0,39] },
        { name: "新疆维吾尔自治区", value: [874,7914,13,8501] },
        { name: "福建省", value: [0,95,5,100]},
        { name: "广西壮族自治区", value: [0,14,0,14] },
        { name: "江苏省", value: [15,217,0,232]},
        { name: "澳门特别行政区", value: [0,0,0,0] },
        { name: "辽宁省", value: [2,28,0,30] },
        { name: "内蒙古自治区", value: [0,13,0,13] },
        { name: "安徽省", value: [12,58,0,70] },
        { name: "云南省", value: [1,4,0,5] },
        { name: "浙江省", value: [87,207,0,294] },
        { name: "重庆市", value: [0,23,0,23] },
        { name: "香港特别行政区", value: [0,0,0,0] },
        { name: "河南省", value: [7,55,0,62] },
        { name: "贵州省", value: [1,10,0,11] },
        { name: "山东省", value: [2,87,0,89] },
        { name: "陕西省", value: [8,27,0,35] },
        { name: "宁夏回族自治区", value: [0,3,0,3] },
        { name: "湖北省", value: [2,28,0,30] },
        { name: "山西省", value: [0,19,0,19] },
        { name: "北京市", value: [55,1005,0,1060] },
        { name: "江西省", value: [4,18,0,22]},
        { name: "海南省", value: [0,5,0,5] },
        { name: "湖南省", value: [3,20,0,23] },
        { name: "吉林省", value: [1,10,0,11] },
        { name: "上海市", value: [5,101,0,106] },
        { name: "台湾省", value: [0,0,0,0] },
        { name: "青海省", value: [0,11,0,11] },
        { name: "天津市", value: [3,18,0,21] },
        { name: "甘肃省", value: [4,27,0,31] },
        { name: "四川省", value: [22,72,0,94] }
         ]
    
    
    
    
    var color1Divide = "";
    var color2Divide = "";
    var color3Divide = "";
    var color1Value = "#DD3F48";
    var color2Value = "#CDB87A";
    var color3Value = "#72B6DB";
    
    export default{
        tableItems1_1,
        tableItems1_2,
        tableItems1_3,
        gmjjdata,
        gmjjdataWG,
        mapData,
        displayname,
        yearValue,
        displayvalue1,
        displayvalue2,
        displayvalue3,
        tableItems2
    }