<template>
  <div id="compontent">
    <dv-border-box-2 backgroundColor="RGBA(77, 144, 254,0.2)">
      <div style="width: 95%; height: 90%" class="out">
        <div class="title">
          <span class="fixed-yellow">专利</span>授权量情况
        </div>
        <div class="content">
          <echart :options="options" :data="yearArr" id="lineCC" :isChange="true" />
        </div>
      </div>
    </dv-border-box-2>
  </div>
</template>
<script>
import echart from "../../components/echarts/echart.vue";
export default {
  components: { echart },
  props: ['isHaveData'],
  data() {
    return {
      options: "",
      allData: '',
      yearArr: [],
      accreditArr: [],
      organArr: []
    };
  },
  mounted() {
    if (this.allData) this.setOption()
  },
  methods: {
    setOption () {
        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            data: ["授权量", "机构数量"],
            textStyle: {
              color: "#FFF",
              fontSize: "0.175rem",
            },
          },
          grid: {
            left: "3%",
            right: "10%",
            bottom: "3%",
            top: "15%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: this.yearArr,
              axisLabel: {
                fontSize: "0.15rem",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                fontSize: "0.15rem",
              },
            },
          ],
          series: [
            {
              name: "授权量",
              type: "line",
              stack: "数量",
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: this.accreditArr,
            },
            {
              name: "机构数量",
              type: "line",
              stack: "家",
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: this.organArr,
            },
          ],
        };
    }
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        if (val) {
            this.allData = this.$store.state.allData.innovate.patentAcc
        }
      },
      immediate: true
    },
    allData: {
      handler: function (val) {
        let data = this.allData
        let yearArr = [] 
        let accreditArr = []
        let organArr = []
        for (let i = 0; i < data.length; i++) {
            yearArr.push(data[i].name)
            accreditArr.push(data[i].accreditNum)
            organArr.push(data[i].organNum)
        }
        this.yearArr = yearArr
        this.accreditArr = accreditArr
        this.organArr = organArr
        this.setOption()
      },
      immediate: true
    }
  }
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
  /* background-color: saddlebrown; */
}
.out {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 10%;
  font-size: 0.2rem /* 16px -> .2rem */;
  font-weight: bold;
  line-height: 0.325rem /* 26px -> .325rem */;
  /* background-color: salmon; */
}
.content {
  height: 88%;
  /* background-color: sandybrown; */
}
</style>