<template>
  <div id="compontent">
    <dv-border-box-10 width="85%" backgroundColor="RGBA(77, 144, 254,0.4)">
      <div
        style="
          height: 95%;
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div class="btns">
          <div
            class="btn"
            @click="clickItem('PC')"
            :class="{ haveChoosen: flag == 'PC' }"
          >
            <dv-border-box-7 class="btn-item">
              <img src="../../../public/imgs/shiyou.png" alt="" />
              <div class="item-text">
                <div style="font-weight: bold">石油化工</div>
                <div><span class="text-num">{{mapALL.PC}}</span>件</div>
              </div>
            </dv-border-box-7>
          </div>
          <div
            class="btn"
            @click="clickItem('CA')"
            :class="{ haveChoosen: flag == 'CA' }"
          >
            <dv-border-box-7 class="btn-item">
              <img src="../../../public/imgs/kuangye.png" alt="" />
              <div class="item-text">
                <div style="font-weight: bold">煤炭煤化工</div>
                <div><span class="text-num">{{mapALL.CA}}</span>件</div>
              </div>
            </dv-border-box-7>
          </div>
          <div
            class="btn"
            @click="clickItem('PW')"
            :class="{ haveChoosen: flag == 'PW' }"
          >
            <dv-border-box-7 class="btn-item">
              <img src="../../../public/imgs/dianli.png" alt="" />
              <div class="item-text">
                <div style="font-weight: bold">电力</div>
                <div><span class="text-num">{{mapALL.PW}}</span>件</div>
              </div>
            </dv-border-box-7>
          </div>
          <div
            class="btn"
            @click="clickItem('FF')"
            :class="{ haveChoosen: flag == 'FF' }"
          >
            <dv-border-box-7 class="btn-item">
              <img src="../../../public/imgs/linye.png" alt="" />
              <div class="item-text">
                <div style="font-weight: bold">林果业</div>
                <div><span class="text-num">{{mapALL.FF}}</span>件</div>
              </div>
            </dv-border-box-7>
          </div>
        </div>
        <div class="title">
          <dv-decoration-7 style="width: 100%; height: 100%"
            >{{ titleObj[flag] }}产业授权专利分布情况</dv-decoration-7
          >
        </div>
        <div class="map">
          <map-echart
            id="mapChartB"
            :mapData="mapData"
            :data="allData"
            :isChange="true"
            mapName="xinjiang"
            :options="options"
            style="height: 100%; width: 100%"
          />
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import mapEchart from "../../components/echarts/mapEchart.vue";
import mapData from "../../../public/mapData/mapData.json";
export default {
  components: { mapEchart },
  name: "IMapData",
  props: ["componentMapData", "componentMapALL"],
  data() {
    return {
      flag: "PC",
      mapData: "",
      allData: "",
      options: "",
      titleObj: {
        PC: "石油化工",
        CA: "煤炭煤化工",
        PW: "电力",
        FF: "林果业",
      },
      mapALL: {
        PC: 0,
        CA: 0,
        PW: 0,
        FF: 0
      }
    };
  },
  mounted() {
    let me = this;
    this.mapData = mapData;
  },
  methods: {
    clickItem(e) {
      this.flag = e;
      this.$emit("clickItem", this.flag);
    },
    setOption() {
        this.options = {
          tooltip: {
            trigger: "item",
            //鼠标放上去显示的数据和文字
            transitionDuration: 0,
            confine: true,
            formatter: (parms) => {
              // console.log(parms)
              return (
                parms.data.name +
                "<br/>" +
                this.titleObj[this.flag] +
                "：<span style='color:#FFF;font-weight:bold;font-size:0.2rem'>" +
                parms.data.value +
                "</span>件"
              );
            },
            backgroundColor: "rgba(0,0,0,0.6)",
            textStyle: {
              color: "rgba(255, 82, 17,1)",
            },
          },
          visualMap: {
            show: false,
            min: 0,
            max: 3000,
            text: ["High", "Low"],
            realtime: false,
            calculable: false,
            inRange: {
              color: ["lightskyblue", "yellow", "orangered"],
            },
          },
          // //在地图旁显示的数据
          series: [
            {
              name: "类型一",
              type: "map",
              mapType: "xinjiang",
              roam: false,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    textStyle: {
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "0.15rem",
                    },
                  },
                  borderColor: "#FFF", //边界线颜色
                  borderWidth: 1, //边界线大小
                  shadowColor: "rgba(255,255,255,0)",
                  shadowBlur: 20,
                  // areaColor: "rgb(21, 41, 77)" //地区背景颜色
                },
                emphasis: {
                  //选中或者悬浮状态
                  label: {
                    show: true,
                    textStyle: {
                      color: "rgba(0,0,0,0.5)", //选中或者悬浮字体颜色
                      fontSize: "0.2rem",
                    },
                  },
                  areaColor: "#2b91b7", //选中或者悬浮区域颜色
                },
              },
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
              data: this.allData,
            },
            {
              name: "Top 5",
              type: "effectScatter",
              coordinateSystem: "geo",
            },
          ],
        };
    }
  },
  watch: {
    componentMapData: {
      handler: function (val) {
        if (val) {
          this.allData = val
          this.setOption()
        }
      },
      immediate: true
    },
    componentMapALL: {
      handler: function (val) {
        if (val) {
          this.mapALL = val
        }
      },
      immediate: true
    }
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.btns {
  height: 12%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* background-color: blanchedalmond; */
}
.title {
  height: 8%;
  font-size: 0.325rem /* 26px -> .325rem */;
  font-weight: bold;
}
.map {
  height: 75%;
  width: 100%;
}
.btn {
  width: 18%;
  cursor: pointer;
}
.btn img {
  width: 0.5rem /* 40px -> .5rem */;
  height: 0.5rem /* 40px -> .5rem */;
  margin-right: 0.0625rem /* 5px -> .0625rem */;
}
.btn-item {
  display: flex;
  justify-content: space-around;
}
.item-text {
  font-size: 0.175rem /* 14px -> .175rem */;
  line-height: 0.25rem /* 20px -> .25rem */;
  text-align: left;
}
.text-num {
  font-size: 0.2125rem /* 17px -> .2125rem */;
  font-weight: bold;
  color: cyan;
}
.haveChoosen {
  background-color: rgba(16, 240, 240, 0.3);
}
</style>