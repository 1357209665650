<template>
  <div id="compontent">
    <dv-border-box-2 backgroundColor="RGBA(77, 144, 254,0.2)">
      <div style="width: 95%; height: 95%" class="out">
        <div class="title">
          <span class="fixed-yellow">各类</span>主体主要研发技术领域(专利授权量)
        </div>
        <div class="content">
          <div class="item">
            <echart
              id="pieZZ"
              :data="allData.qy"
              :options="optionsA"
              :isChange="true"
            />
          </div>
          <div class="item">
            <echart
              id="pieYY"
              :data="allData.kyjg"
              :options="optionsB"
              :isChange="true"
            />
          </div>
          <div class="item">
            <echart
              id="pieXX"
              :data="allData.xx"
              :options="optionsC"
              :isChange="true"
            />
          </div>
          <div class="item">
            <echart
              id="pieQQ"
              :data="allData.jgtt"
              :options="optionsD"
              :isChange="true"
            />
          </div>
        </div>
      </div>
    </dv-border-box-2>
  </div>
</template>
<script>
import echart from "../../components/echarts/echart.vue";
import data from "../../../public/jsData/innovate";
export default {
  props: ['isHaveData'],
  components: { echart },
  data() {
    return {
      optionsA: "",
      optionsB: "",
      optionsC: "",
      optionsD: "",
      allData: {
        qy: '',
        xx: '',
        kyjg: '',
        jgtt: ''
      }
    };
  },
  mounted() {
    if (this.allData.qy) this.setOption()
  },
  methods: {
    setOption () {
        this.optionsA = {
          title: {
            text: "企业",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: "0.25rem",
              color: "#24d6dc",
              align: "center",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: function (res) {
            //   console.log(res);
              return (
                '<div style="color:' +
                res.color +
                ';font-size:0.2rem;max-width:1.5rem;white-space:normal; word-break:break-all;overflow:hidden;">' +
                res.name +
                '</div><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:#fff;font-weight:bold;font-size:0.2rem">' +
                res.value +
                "</span>"
              );
            },
            backgroundColor: "rgba(0,0,0,0.3)",
            confine: true,
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              data: this.allData.qy,
            },
          ],
        };
        this.optionsB={
          title: {
            text: "科研机构",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: "0.25rem",
              color: "#24d6dc",
              align: "center",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: function (res) {
            //   console.log(res);
              return (
                '<div style="color:' +
                res.color +
                ';font-size:0.2rem;max-width:1.5rem;white-space:normal; word-break:break-all;overflow:hidden;">' +
                res.name +
                '</div><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:#fff;font-weight:bold;font-size:0.2rem">' +
                res.value +
                "</span>"
              );
            },
            backgroundColor: "rgba(0,0,0,0.3)",
            confine: true,
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              data: this.allData.kyjg,
            },
          ],
        };
        this.optionsC={
          title: {
            text: "大专院校",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: "0.25rem",
              color: "#24d6dc",
              align: "center",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: function (res) {
            //   console.log(res);
              return (
                '<div style="color:' +
                res.color +
                ';font-size:0.2rem;max-width:1.5rem;white-space:normal; word-break:break-all;overflow:hidden;">' +
                res.name +
                '</div><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:#fff;font-weight:bold;font-size:0.2rem">' +
                res.value +
                "</span>"
              );
            },
            backgroundColor: "rgba(0,0,0,0.3)",
            confine: true,
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              data: this.allData.xx,
            },
          ],
        };
        this.optionsD={
          title: {
            text: "机关团体",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: "0.25rem",
              color: "#24d6dc",
              align: "center",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: function (res) {
            //   console.log(res);
              return (
                '<div style="color:' +
                res.color +
                ';font-size:0.2rem;max-width:1.5rem;white-space:normal; word-break:break-all;overflow:hidden;">' +
                res.name +
                '</div><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:#fff;font-weight:bold;font-size:0.2rem">' +
                res.value +
                "</span>"
              );
            },
            backgroundColor: "rgba(0,0,0,0.3)",
            confine: true,
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              data: this.allData.jgtt,
            },
          ],
        };
    }
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        if (val) {
          let data = this.$store.state.allData.innovate
          this.allData.qy = data.enterpriseFieldNum
          this.allData.xx = data.collegeFieldNum
          this.allData.kyjg = data.institutionFieldNum
          this.allData.jgtt = data.organFieldNum
        }
      },
      immediate: true
    },
    allData: {
      handler: function (val) {
        if (val) {
          this.setOption()
        }
      },
      immediate: true,
      deep: true
    }
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
  /* background-color: sandybrown; */
}
.out {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 10%;
  font-size: 0.2rem /* 16px -> .2rem */;
  font-weight: bold;
  line-height: 0.5rem /* 40px -> .5rem */;
  /* background-color: sandybrown; */
}
.content {
  height: 88%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.item {
  width: 48%;
  height: 48%;
  /* background-color: seagreen; */
}
</style>