var tableItems1 = [
    ['01','F24F5/00',102],
    ['02','C10M169/04',38],
    ['03','E04B2/88',26],
    ['04','G06K9/00',20],
    ['05','A01D46/00',19],
    ['06','B01D50/00',16],
    ['07','A01D45/02',16],
    ['08','F21S9/03',14],
    ['09','A01G25/02',13],
    ['10','E02D17/20',13]
    ]
    
    
    var tableItems2 = [
        ['01','新疆工程学院',1403],
        ['02','新疆畜牧科学院畜牧研究所',198],
        ['03','新疆畜牧科学院草业研究所',176],
        ['04','新疆绿色使者空气环境技术有限公司',171],
        ['05','新疆远麟阳光幕墙装饰工程有限公司',170],
        ['06','新疆机械研究院股份有限公司',169],
        ['07','新疆八钢金属制品有限公司',160],
        ['08','新疆绿色使者干空气能源有限公司',157],
        ['09','新疆新研牧神科技有限公司',155],
        ['10','新疆广汇中化能源技术开发有限公司',154],
        ['11','新疆嘉盛阳光风电科技股份有限公司',154],
        ['12','新疆中泰创新技术研究院有限责任公司',152],
        ['13','新疆新能钢结构有限责任公司',148],
        ['14','新疆福克油品股份有限公司',148],
        ['15','新疆有色金属研究所',144],
        ['16','新疆华油技术服务股份有限公司',142],
        ['17','新疆维泰开发建设（集团）股份有限公司',141],
        ['18','新疆七星建设科技股份有限公司',140],
        ['19','新疆中亚食品研发中心（有限公司）',140],
        ['20','新疆天山电梯制造有限公司',139]
    ]
    
    
    //专利类型饼图
    let zllxdata=[
        {name:'发明',value:251},
        {name:"新型",value:2477},
        {name:"外观",value:211}
    ]
    
    //中2_2使用数据
    let zlyydata=[
        {name:'许可',value:36},
        {name:'转让',value:749},
        {name:'质押',value:47}
    ]
    
    
     //中2_1国民经济分类饼图
    //  var gmjjdata =	['A 农、林、牧、渔业','B 采矿业','C 制造业','D 电力、热力、燃气及水生产和供应业','E 建筑业','I 信息传输、软件和信息技术服务业','O 居民服务、修理和其他服务业'];
    //  var gmjjdata1 =	['79','18','2543','137','212','25','94'];
    //  var gmjjdataWG =	['17 纺织业','18 纺织服装、服饰业','20 木材加工和木、竹、藤、棕、草制品业','24 文教、工美、体育和娱乐用品制造业',
    //  '26 化学原料和化学制品制造业','29 橡胶和塑料制品业','30 非金属矿物制品业','31 黑色金属冶炼和压延加工业',
    //  '33 金属制品业','34 通用设备制造业','35 专用设备制造业','38 电气机械和器材制造业','39 计算机、通信和其他电子设备制造业','40 仪器仪表制造业','41 其他制造业'];
    //  var gmjjdataWGValue =	['10','6','54','12','4','114','39','3','88','23','25','24','25','2','4'];
    let gmjjdata=[
        {name:"A 农、林、牧、渔业",value:79},
        {name:'B 采矿业',value:18},
        {name:'C 制造业',value:2543},
        {name:'D 电力、热力、燃气及水生产和供应业',value:137},
        {name:'E 建筑业',value:212},
        {name:'I 信息传输、软件和信息技术服务业',value:25},
        {name:'O 居民服务、修理和其他服务业',value:94}
    ]
    let gmjjdataWG=[
        {name:'17 纺织业',value:10},
        {name:'18 纺织服装、服饰业',value:6},
        {name:'20 木材加工和木、竹、藤、棕、草制品业',value:54},
        {name:'24 文教、工美、体育和娱乐用品制造业',value:12},
        {name:'26 化学原料和化学制品制造业',value:4},
        {name:'29 橡胶和塑料制品业',value:114},
        {name:'30 非金属矿物制品业',value:39},
        {name:'31 黑色金属冶炼和压延加工业',value:3},
        {name:'33 金属制品业',value:88},
        {name:'34 通用设备制造业',value:23},
        {name:'35 专用设备制造业',value:25},
        {name:"38 电气机械和器材制造业",value:24},
        {name:"39 计算机、通信和其他电子设备制造业",value:25},
        {name:"40 仪器仪表制造业",value:2},
        {name:"41 其他制造业",value:4}
    ]

    /*
    [{"name":"许可","value":36},{"name":"转让","value":749},{"name":"质押","value":47}] pieGroups.vue:106
[{"name":"许可","value":36},{"name":"转让","value":749},{"name":"质押","value":47}] pieGroups.vue:106
[{"name":"许可","value":36},{"name":"转让","value":749},{"name":"质押","value":47}]


    */

    //计时器
    var table1Interval = '';
    var table2Interval = '';
     //加载固定数据
    var data1 = 	"1" //企业数
    var data2 = 	86803
    var data3 = 	2939
    var data4 = 	7720

    export default{
        tableItems1,
        tableItems2,
        zllxdata,
        zlyydata,
        gmjjdata,
        gmjjdataWG
    }