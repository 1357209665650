<template>
  <div id="compontent">
    <dv-border-box-7 backgroundColor="rgba(93, 93, 95,0.3)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">自治</span>区辖区排名
        </div>
        <div class="btns">
          <div class="btn" :class="{ btnBeenChoosen: 1 == flag }" @click="flag=1">技术输出</div>
          <div
            class="btn"
            style="
              border-left: 2px solid rgba(189, 189, 189, 0.4);
              border-right: 2px solid rgba(189, 189, 189, 0.4);
            "
            :class="{ btnBeenChoosen: 2 == flag }"
            @click="flag = 2"
          >
            技术输入
          </div>
          <div class="btn" :class="{ btnBeenChoosen: 3 == flag }" @click="flag=3">本地转化</div>
        </div>
        <div class="content">
          <dv-scroll-board :config="config" style="width: 100%; height: 100%" />
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import data from "../../../public/jsData/patent";
export default {
  props: ['isHaveData'],
  data() {
    return {
      flag: 1,
      config: {},
      flagArr: [
        {
          name: '技术输出',
          world: 'techExportRank',
          titleThree: '从自治区转出'
        },
        {
          name: '技术输入',
          world: 'techImportRank',
          titleThree: '转入到自治区'
        },
        {
          name: '本地转化',
          world: 'localTransRank',
          titleThree: '本地转化'
        }
      ],
      allData: {
        techExportRank: '',
        techImportRank: '',
        localTransRank: ''
      }
    };
  },
  mounted() {

  },
  methods: {
    setConfig () {
      let {flag} = this
      let world = this.flagArr[flag - 1].world
      let titleThree = this.flagArr[flag - 1].titleThree
      let data = JSON.parse(JSON.stringify(this.allData[world]))
      let configData = []
      for (let i = 0; i < data.length; i++) {
        let num = data[i][0]
          configData.push([
            '<span style="font-size:0.17rem">' +
              data[i][0] +
              "</span>",
            '<span style="font-size:0.17rem">' +
              data[i][1] +
              "</span>",
            '<span style="font-size:0.17rem">' +
              data[i][2] +
              "</span>",
          ]);
        if (num === '01' || num === '02' || num === '03') {
          configData[i] = [
              '<span style="font-size:0.18rem;color:yellow;font-weight:bold">' +
                data[i][0] +
                "</span>",
              '<span style="font-size:0.18rem;color:yellow;font-weight:bold">' +
                data[i][1] +
                "</span>",
              '<span style="font-size:0.18rem;color:yellow;font-weight:bold">' +
                data[i][2] +
                "</span>",
            ];
        }
      }
        this.config = {
          header: [
            "<span style='font-size:0.175rem'>排名</span>",
            "<span style='font-size:0.175rem'>地区</span>",
            "<span style='font-size:0.175rem'>"+titleThree+"</span>",
          ],
          data: configData,
          align: ["center", "center", "center"],
          oddRowBGC: "RGBA(77, 144, 254,0)",
          evenRowBGC: "RGBA(101, 101, 103,0.1)",
          headerBGC: "RGBA(77, 144, 254,0.3)",
        };

    }
  },
  watch: {
    flag: {
      handler: function (val) {
        this.setConfig()
      },
    },
    isHaveData: {
      handler: function (val) {
        if (val) {
          let data = this.$store.state.allData.patent
          this.allData.localTransRank = data.localTransRank
          this.allData.techExportRank = data.techExportRank
          this.allData.techImportRank = data.techImportRank
        }
      },
      immediate: true
    },
    allData: {
      handler: function (val) {
        this.setConfig()
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style scoped>
#compontent {
  height: 100%;
  width: 100%;
  /* background-color: cadetblue; */
}
.out {
  width: 95%;
  height: 95%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.title {
  height: 8%;
  font-size: 0.225rem /* 18px -> .225rem */;
  font-weight: bold;
  line-height: .375rem /* 30px -> .375rem */;
}
.btns {
  display: flex;
  height: 8%;
  padding: 2% 0;
}
.btn {
  /* padding: 20px; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.175rem /* 14px -> .175rem */;
  font-weight: bold;
  color: rgba(189, 189, 189, 0.6);
  background-color: rgba(189, 189, 189, 0.1);
  cursor: pointer;
}
.content {
  /* background-color: chartreuse; */
  height: 80%;
}
.btnBeenChoosen {
  color: #fff;
  background-color: rgba(59, 246, 255, 0.2);
}
</style>