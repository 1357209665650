<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">近五</span>年专利授权量/商标注册量
        </div>
        <div class="content">
          <echart
            id="BarB"
            :options="options"
            :data="yearArr"
            :isChange="true"
            @clickToSearch="clickChart"
          />
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  components: { Echart },
  name: "barChartNum",
  props: ["isHaveData"],
  data() {
    return {
      options: "",
      allData: "",
      yearArr: "",
      authArr: "",
      brandArr: "",
    };
  },
  mounted() {
    if (this.$store.state.allData.total) {
      this.allData = this.$store.state.allData.total.authPatentRecently;
    }
  },
  methods: {
    clickChart(e) {
      // console.log(e)
    },
    setOption() {
      this.options = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.5)",
          textStyle: {
            color: "#FFF",
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          top: 40,
          x: 70,
          x2: 45,
          y2: 30,
        },
        xAxis: [
          {
            type: "category",
            data: this.yearArr,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              fontSize: "0.15rem",
            },
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "#5c99c5",
              fontSize: "0.15rem",
            },
            type: "value",
            name: "数量(件)",
            axisLabel: {
              fontSize: "0.15rem",
            },
          },
        ],
        series: [
          {
            name: "商标注册量",
            type: "bar",
            data: this.brandArr,
          },
          {
            name: "专利授权量",
            type: "bar",
            data: this.authArr,
          },
        ],
      };
    },
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        this.allData = this.$store.state.allData.total.authPatentRecently;
      },
    },
    allData: {
      handler: function (val) {
        if (val) {
          let data = this.allData;
          let yearArr = [];
          let authArr = [];
          let brandArr = [];
          for (let i = 0; i < data.length; i++) {
            yearArr.push(data[i].year + "年");
            authArr.push(data[i].auth);
            brandArr.push(data[i].brand);
          }
          this.yearArr = yearArr;
          this.authArr = authArr;
          this.brandArr = brandArr;
          this.setOption();
        }
      },
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 95%;
}
.out {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
</style>