<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div id="out">
        <div class="item title">
          <span class="fixed-yellow">授权</span>专利国民经济行业分布
        </div>
        <div class="item content">
          <div class="item-chlid">
            <div class="fixed-chlid">发明新型</div>
            <div id="leftPie">
              <echart
                id="pie"
                :options="optionsA"
                :data="allData.invent"
                :isChange="true"
                @clickToSearch="clickInvent"
              />
            </div>
          </div>
          <div class="item-chlid">
            <div class="fixed-chlid">外观设计</div>
            <div id="rightPie">
              <echart
                id="pieA"
                :options="optionsB"
                :data="allData.facade"
                :isChange="true"
                @clickToSearch="clickFacade"
              />
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  name: "pieChartIndustry",
  components: { Echart },
  props: ['isHaveData'],
  data() {
    return {
      optionsA: "",
      optionsB: "",
      allData: "",
    };
  },
  mounted() {
    if (this.$store.state.allData.total) {
      this.allData = this.$store.state.allData.total.industryClass
    }
  },
  methods: {
    clickInvent(e) {
      // const {data} = e
      // console.log(data)
      // let condition = '(type:发明公开 OR type:发明授权 OR type: 实用新型) AND (province:新疆维吾尔自治区) AND ()'
    },
    clickFacade(e) {
      // console.log(2)
    },
    setOption() {
      // 发明新型
      this.optionsA = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.2)",
          textStyle: {
            color: "#FFF",
          },
          position: function (point, params, dom, rect, size) {
            return {
              left: point[0] + 10,
              top: point[1] + 10,
            };
          },
          confine: true,
          formatter: function (res) {
            return (
              '<span style="font-size:0.175rem">' +
              res.data.name +
              "<br/>" +
              "数量：</span>" +
              '<span style="font-size:0.2rem;font-weight:bold">' +
              res.data.value +
              "</span>"
            );
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["100%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 0,
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: this.allData.invent,
          },
        ],
      };
      // 外观设计
      this.optionsB = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.2)",
          textStyle: {
            color: "#FFF",
          },
          position: function (point, params, dom, rect, size) {
            return {
              left: point[0] + 10,
              top: point[1] + 10,
            };
          },
          confine: true,
          formatter: function (res) {
            return (
              '<span style="font-size:0.175rem">' +
              res.data.name +
              "<br/>" +
              "数量：</span>" +
              '<span style="font-size:0.2rem;font-weight:bold">' +
              res.data.value +
              "</span>"
            );
          },
        },
        series: [
          {
            name: "数量",
            type: "pie",
            radius: ["100%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 0,
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: this.allData.facade,
          },
        ],
      };
    },
  },
  watch: {
    isHaveData: {
      handler: function (val) {
        this.allData = this.$store.state.allData.total.industryClass
      }
    },
    allData: {
      handler: function (val) {
        if (val) {
          this.setOption()
        }
      }
    }
  }
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
#out {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
.item-chlid {
  width: 45%;
  height: 100%;
}
.fixed-chlid {
  font-size: 0.2rem;
  height: 15%;
  line-height: 0.3125rem /* 25px -> .3125rem */;
}
#leftPie,
#rightPie {
  height: 82%;
  width: 100%;
}
</style>
