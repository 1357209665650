<template>
  <div id="compontent">
    <dv-border-box-10 width="85%" backgroundColor="RGBA(77, 144, 254,0.4)">
      <div
        style="
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
        "
      >
        <div id="echart" style="width: 100%; height: 75%">
          <map-echart
            id="mapEchartA"
            :options="options"
            :data="allData[flag]"
            :mapData="mapData"
            mapName="xinjiang"
            :isChange="true"
            style="width: 100%; height: 100%"
          ></map-echart>
        </div>
        <div id="btns">
          <dv-border-box-7 class="border" v-show="!(flag == 'ZL')">
            <div class="borderContent" @click="clickItem('ZL')">
              <img src="../../../public/imgs/zhuanli.png" alt="" />
              <div class="text">
                <div class="fixed">专利授权量</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.ZL}}</span>件</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'ZL'">
            <div class="borderContent" @click="clickItem('ZL')">
              <img src="../../../public/imgs/zhuanli.png" alt="" />
              <div class="text">
                <div class="fixed">专利授权量</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.ZL}}</span>件</div>
              </div>
            </div>
          </dv-border-box-8>

          <dv-border-box-7 class="border" v-show="!(flag == 'SB')">
            <div class="borderContent" @click="clickItem('SB')">
              <img src="../../../public/imgs/shangbiao.png" alt="" />
              <div class="text">
                <div class="fixed">商标注册量</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.SB}}</span>件</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'SB'">
            <div class="borderContent" @click="clickItem('SB')">
              <img src="../../../public/imgs/shangbiao.png" alt="" />
              <div class="text">
                <div class="fixed">商标注册量</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.SB}}</span>件</div>
              </div>
            </div>
          </dv-border-box-8>

          <dv-border-box-7 class="border" v-show="!(flag == 'DB')">
            <div class="borderContent" @click="clickItem('DB')">
              <img src="../../../public/imgs/dili.png" alt="" />
              <div class="text">
                <div class="fixed">地理标志</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.DB}}</span>个</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'DB'">
            <div class="borderContent" @click="clickItem('DB')">
              <img src="../../../public/imgs/dili.png" alt="" />
              <div class="text">
                <div class="fixed">地理标志</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.DB}}</span>个</div>
              </div>
            </div>
          </dv-border-box-8>

          <dv-border-box-7 class="border" v-show="!(flag == 'DL')">
            <div class="borderContent" @click="clickItem('DL')">
              <img src="../../../public/imgs/dianlu.png" alt="" />
              <div class="text">
                <div class="fixed">集成电路</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.DL}}</span>件</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'DL'">
            <div class="borderContent" @click="clickItem('DL')">
              <img src="../../../public/imgs/dianlu.png" alt="" />
              <div class="text">
                <div class="fixed">集成电路</div>
                <div class="fixed"><span class="fixedNum">{{fixedData.DL}}</span>件</div>
              </div>
            </div>
          </dv-border-box-8>
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import xjData from "../../../public/mapData/mapData.json";
import MapEchart from "../../components/echarts/mapEchart.vue";
export default {
  components: { MapEchart },
  name: "mapData",
  props: ["isHaveData"],
  data() {
    return {
      flag: "ZL",
      allData: [],
      title: "",
      titleObj: {
        ZL: "专利授权量",
        SB: "商标注册量",
        DB: "地理标志",
        DL: "集成电路",
      },
      options: {},
      mapData: "",
      fixedData: {}
    };
  },
  mounted() {
    this.mapData = xjData;
    if (this.$store.state.allData.total) {
        let data = {}
        let str = this.$store.state.allData.total
        data.ZL = str.mapZLData
        data.SB = str.mapSBData
        data.DB = str.mapDBData
        data.DL = str.mapDLData
        this.allData = data
        let data_ = {}
        data_.ZL = str.mapZLALL
        data_.SB = str.mapSBALL
        data_.DB = str.mapDBALL
        data_.DL = str.mapDLALL
        this.fixedData = data_
    }
  },
  methods: {
    clickItem(flag) {
      this.flag = flag;
    },
    setOption() {
      this.options = {
        tooltip: {
          trigger: "item",
          //鼠标放上去显示的数据和文字
          transitionDuration: 0,
          confine: true,
          formatter: (parms) => {
            if (parms.data) {
              return (
              parms.data.name +
              "<br/>" +
              this.titleObj[this.flag] +
              "<br/>" +
              "数量:" +
              "&nbsp;&nbsp;" +
              "<span style='font-size:0.2rem;font-weight:bold;color:rgba(255, 255, 255,1)'>" +
              parms.data.value +
              "</span>"
            );
            }
          },
          backgroundColor: "rgba(0,0,0,0.6)",
          textStyle: {
            color: "rgba(255, 82, 17,1)",
          },
        },
        visualMap: {
          show: false,
          min: 200,
          max: 50000,
          text: ["High", "Low"],
          realtime: false,
          calculable: false,
          inRange: {
            color: ["lightskyblue", "yellow", "orangered"],
          },
        },
        // //在地图旁显示的数据
        series: [
          {
            name: "类型一",
            type: "map",
            mapType: "xinjiang",
            roam: false,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: {
                    color: "rgba(0,0,0,0.5)",
                    fontSize: "0.2rem",
                  },
                },
                borderColor: "#FFF", //边界线颜色
                borderWidth: 1, //边界线大小
                shadowColor: "rgba(255,255,255,0.1)",
                shadowBlur: 20,
                // areaColor: "rgb(21, 41, 77)" //地区背景颜色
              },
              emphasis: {
                //选中或者悬浮状态
                label: {
                  show: true,
                  textStyle: {
                    color: "rgba(0,0,0,0.5)", //选中或者悬浮字体颜色
                  },
                },
                areaColor: "#2b91b7", //选中或者悬浮区域颜色
              },
            },
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: true,
              },
            },
            data: this.allData[this.flag],
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "geo",
          },
        ],
      };
    },
  },
  watch: {
    flag: {
      handler: function (val, old) {
        if (this.allData) this.setOption()
      },
      deep: true,
      immediate: true,
    },
    isHaveData: {
      handler: function (val) {
        let data = {}
        let str = this.$store.state.allData.total
        data.ZL = str.mapZLData
        data.SB = str.mapSBData
        data.DB = str.mapDBData
        data.DL = str.mapDLData
        this.allData = data
        let data_ = {}
        data_.ZL = str.mapZLALL
        data_.SB = str.mapSBALL
        data_.DB = str.mapDBALL
        data_.DL = str.mapDLALL
        this.fixedData = data_
      }
    },
    allData: {
      handler: function (val) {
        if (val) {
          this.setOption()
        }
      }
    }
  },
};
</script>
<style scoped>
#compontent {
  /* align-items: ; */
  width: 100%;
  height: 100%;
}
#btns {
  /* background-color: saddlebrown; */
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
}
#echart {
  display: flex;
  align-items: center;
}
.border {
  cursor: pointer;
  width: 1.75rem /* 140px -> 1.75rem */;
  height: 1rem /* 100px -> 1.25rem */;
  display: flex;
  align-items: center;
  padding-right: 0.125rem;
}
.borderA {
  width: 1.85rem /* 148px -> 1.85rem */;
  height: 1.1rem /* 88px -> 1.1rem */;
  /* background-color: turquoise; */
}
.border img {
  width: 0.5rem /* 40px -> .5rem */;
  height: 0.5rem /* 40px -> .5rem */;
}
.borderContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.fixed {
  font-size: 0.1875rem /* 15px -> .1875rem */;
  font-weight: bold;
  text-align: center;
}
.fixedNum {
  font-size: 0.275rem /* 22px -> .275rem */;
  font-weight: bolder;
  color: #38eaff;
}
</style>