<template>
  <div id="compontent">
    <dv-border-box-10
      width="90%"
      height="90%"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div
        style="
          height: 95%;
          width: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div class="top">
          <div class="title">
            <dv-decoration-7 style="width: 100%; height: 0.375rem"
              >乌鲁木齐经济技术开发区（头屯河区）</dv-decoration-7
            >
          </div>
          <div class="content">
            <map-echart
              :mapData="mapData"
              mapName="xinjiang"
              id="mapChartDd"
              :options="options"
              :isChange="false"
              style="width: 100%; height: 100%"
              :isFixed="true"
              data='1'
            />
            <div class="infor">
              乌鲁木齐经济技术开发区1994年8月经国务院批准设立，
              是全疆第—个国家级经开区;2011年1月，与市辖行政区头屯河区合并，
              实行“区政合—”，成为集国家级开发区、行政区、国际陆港区、
              综合保税区及若干特色功能园区于—体的多功能复合型经济区。
              是丝绸之路经济带核心区建设的重要承载区。全区规划管理面积490平方公里,
              总人口35万人，辖区注册企业2.1万家，其中四上企业623家。
              工业总产值亿元以上企业43家，外商投资企业47家，世界500强22家，中国500强40家。
            </div>
          </div>
        </div>
        <div class="bottom">
          <div
            class="item"
            @click="clickItem({ name: '家', value: 86803, flag: 0 })"
            :class="{ beenChoosen: flag == 0 }"
          >
            <img :src="src1" alt="" />
            <div class="item-fixed">机构总量</div>
          </div>
          <div
            class="item"
            @click="clickItem({ name: '件', value: 2939, flag: 1 })"
            :class="{ beenChoosen: flag == 1 }"
          >
            <img :src="src2" alt="" />
            <div class="item-fixed">专利总量</div>
          </div>
          <div
            class="item"
            @click="clickItem({ name: '件', value: 7720, flag: 2 })"
            :class="{ beenChoosen: flag == 2 }"
          >
            <img :src="src3" alt="" />
            <div class="item-fixed">商标总量</div>
          </div>
          <div class="num">
            <dv-digital-flop
              :config="config"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import mapData from "../../../public/mapData/wulumuqiMap.json";
import mapEchart from "../../components/echarts/mapEchart.vue";
export default {
  components: { mapEchart },
  props: ['isHaveData'],
  data() {
    return {
      mapData: "",
      options: "",
      config: {
        number: [86803],
        style: {
          fontSize: 50,
        },
        content: "{nt}家",
      },
      flag: 0,
      src1: require("../../../public/imgs/jigouNumChoose.png"),
      src2: require("../../../public/imgs/zhuanliNumChoose.png"),
      src3: require("../../../public/imgs/shangbiaoNumChoose.png"),
      intervalId: "",
    };
  },
  mounted() {
    // console.log(mapData)
    this.mapData = mapData;
    this.interval();
  },
  methods: {
    clickItem(e) {
      this.flag = e.flag;
      clearInterval(this.intervalId);
      this.interval();
    },
    interval() {
      let me = this;
      let i = this.flag;
      this.intervalId = setInterval(function () {
        if (i > 2) {
          i = 0;
        }
        me.flag = i;
        i++;
      }, 2500);
    },
  },
  watch: {
    mapData: {
      handler: function (val) {
        this.options = {
          tooltip: {},
          // //在地图旁显示的数据
          series: [
            {
              name: "类型一",
              type: "map",
              mapType: "xinjiang",
              roam: false,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    textStyle: {
                      color: "#f4e925",
                      fontSize: "0.15rem",
                    },
                  },
                  borderColor: "#3fdaff", //边界线颜色
                  borderWidth: 1, //边界线大小
                  shadowColor: "rgba(255,255,255,0)",
                  shadowBlur: 20,
                  areaColor: "rgb(21, 41, 77)", //地区背景颜色
                },
                emphasis: {
                  //选中或者悬浮状态
                  label: {
                    show: false,
                    textStyle: {
                      color: "#fff", //选中或者悬浮字体颜色
                    },
                  },
                  areaColor: "rgba(212, 58, 0,1)", //选中或者悬浮区域颜色
                },
              },
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
              center: [87.425823, 43.876053],
            },
          ],
        };
      },
    },
    flag: {
      handler: function (val) {
        if (val == 0) {
          this.src1 = require("../../../public/imgs/jigouNum.png");
          this.src2 = require("../../../public/imgs/zhuanliNumChoose.png");
          this.src3 = require("../../../public/imgs/shangbiaoNumChoose.png");
          this.config = {
            number: [86803],
            style: {
              fontSize: 40,
            },
            content: "{nt}家",
          };
        } else if (val == 1) {
          this.src1 = require("../../../public/imgs/jigouNumChoose.png");
          this.src2 = require("../../../public/imgs/zhuanliNum.png");
          this.src3 = require("../../../public/imgs/shangbiaoNumChoose.png");
          this.config = {
            number: [2939],
            style: {
              fontSize: 40,
            },
            content: "{nt}件",
          };
        } else if (val == 2) {
          this.src1 = require("../../../public/imgs/jigouNumChoose.png");
          this.src2 = require("../../../public/imgs/zhuanliNumChoose.png");
          this.src3 = require("../../../public/imgs/shangbiaoNum.png");
          this.config = {
            number: [7720],
            style: {
              fontSize: 40,
            },
            content: "{nt}件",
          };
        }
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  }
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
  /* background-color: #00FFFF; */
}

.bottom {
  width: 100%;
  height: 15%;
  /* background-color: #05304d; */
}
.top {
  width: 100%;
  height: 82%;
  /* background-color: #00FFFF; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 8%;
  width: 100%;
  font-size: 0.3125rem;
  font-weight: bold;
  text-align: center;
  line-height: 0.625rem;
}
.content {
  width: 100%;
  height: 90%;
  position: relative;
}
.infor {
  position: absolute;
  /* top:65%; */
  bottom: 0%;
  left: 0;
  font-size: 0.225rem /* 18px -> .225rem */;
  line-height: 0.375rem /* 30px -> .375rem */;
  padding: 0.25rem /* 20px -> .25rem */;
  background-color: rgba(21, 37, 69, 0.8);
  align-items: center;
  letter-spacing: 0.025rem /* 2px -> .025rem */;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bottom img {
  width: 0.625rem /* 50px -> .625rem */;
  height: 0.625rem /* 50px -> .625rem */;
}
.item {
  padding: 0.1rem /* 8px -> .1rem */;
  width: 18%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: rgba(65, 105, 225,0.4) 4px solid; */
  border: rgba(205, 205, 205, 0.2) 4px solid;
  color: rgba(205, 205, 205, 0.2);
}
.num {
  width: 30%;
  height: 100%;
}
.item-fixed {
  margin-top: 0.125rem /* 10px -> .125rem */;
  font-size: 0.2rem /* 16px -> .2rem */;
  font-weight: bold;
}
.beenChoosen {
  color: white;
  border: rgba(65, 105, 225, 0.4) 4px solid;
}
</style>
