let SYData = {
    name: "石油化工",
    scrollTable: [
        ['01', '中国石油集团西部钻探工程有限公司', 931],
        ['02', '新疆石油管理局钻井工艺研究院', 178],
        ['03', '宝钢集团新疆八一钢铁有限公司', 64],
        ['04', '中国石油集团西部钻探工程有限公司克拉玛依钻井工艺研究院', 62],
        ['05', '新疆石油管理局采油工艺研究院', 57],
        ['06', '西部钻探克拉玛依钻井工艺研究院', 46],
        ['07', '新疆华油油气工程有限公司', 43],
        ['08', '新疆国利衡清洁能源科技有限公司', 40],
        ['09', '克拉玛依胜利高原机械有限公司', 39],
        ['10', '新疆维吾尔自治区第三机床厂', 37]
    ],
    //近五年授权量
    fImpowerNum: ['291', '310', '371', '301', '501'],
    //专利类型分布 外观 新型发明
    patentClass: [{name:"外观",value:620}, {name:"新型",value:3789}, {name:"发明",value:6}],
    industryClassName: ['E固定建筑物', 'C化学；冶金', 'F机械工程', 'B作业；运输', 'G物理'],
    industryClassValue: ['2955', '703', '278', '269', '166'],
    //专利运营情况
    operation:{
        total:{ZY:31,ZR:1016,XK:41},
        ZY:[
            {name:"转出",value:31},
            {name:"转入",value:0},
            {name:"内部",value:0}
        ],
        ZR:[
            {name:"转出",value:532},
            {name:"转入",value:28},
            {name:"内部",value:456}
        ],
        XK:[
            {name:"转出",value:9},
            {name:"转入",value:14},
            {name:"内部",value:18}
        ]
    },
    mapData: [{ name: "乌鲁木齐市", value: [87.617733, 43.792818, 1121] },
    { name: "克拉玛依市", value: [84.873946, 45.595886, 2009] },
    { name: "吐鲁番市", value: [89.184078, 42.947613, 323] },
    { name: "哈密市", value: [93.51316, 42.833248, 113] },
    { name: "昌吉回族自治州", value: [87.304012, 44.014577, 185] },
    { name: "博尔塔拉蒙古自治州", value: [82.074778, 44.903258, 5] },
    { name: "巴音郭楞蒙古自治州", value: [86.150969, 41.768552, 383] },
    { name: "阿克苏地区", value: [80.265068, 41.170712, 56] },
    { name: "克孜勒苏柯尔克孜自治州", value: [76.172825, 39.713431, 2] },
    { name: "喀什地区", value: [75.989138, 39.467664, 29] },
    { name: "和田地区", value: [79.92533, 37.110687, 5] },
    { name: "伊犁哈萨克自治州", value: [81.317946, 43.92186, 89] },
    { name: "塔城地区", value: [82.985732, 46.746301, 24] },
    { name: "阿勒泰地区", value: [88.13963, 47.848393, 9] },
    { name: "石河子市", value: [86.041075, 44.305886, 40] },
    { name: "阿拉尔市", value: [81.285884, 40.541914, 9] },
    { name: "图木舒克市", value: [79.077978, 39.867316, 0] },
    { name: "五家渠市", value: [87.526884, 44.167401, 15] },
    { name: "北屯市", value: [87.824932, 47.353177, 0] },
    { name: "铁门关市", value: [85.501218, 41.827251, 0] },
    { name: "双河市", value: [82.353656, 44.840524, 0] },
    { name: "可克达拉市", value: [80.63579, 43.6832, 0] },
    { name: "昆玉市", value: [79.287372, 37.207994, 0] },
    { name: "胡杨河市", value: [84.8275959, 44.69288853, 0] },
    ]

}

let MTData = {
    name: "煤炭煤化工",
    scrollTable: [
        ['01', "中国石油集团西部钻探工程有限公司", 106],
        ['02', "新疆石油管理局钻井工艺研究院", 48],
        ['03', "宝钢集团新疆八一钢铁有限公司", 43],
        ['04', "中国石油集团西部钻探工程有限公司克拉玛依钻井工艺研究院", 42],
        ['05', "新疆石油管理局采油工艺研究院", 30],
        ['06', "西部钻探克拉玛依钻井工艺研究院", 25],
        ['07', "新疆华油油气工程有限公司", 23],
        ['08', "新疆国利衡清洁能源科技有限公司", 21],
        ['09', "克拉玛依胜利高原机械有限公司", 20],
        ['10', "新疆维吾尔自治区第三机床厂", 20]
    ],
    //近五年授权量
    fImpowerNum:['152', '191', '234', '203', '267'],
    //专利类型分布 外观 新型发明
    patentClass: [{name:"外观",value:467}, {name:"新型",value:1512}, {name:"发明",value:3}],
    industryClassName: ['C化学；冶金', 'B作业；运输', 'F机械工程', 'E固定建筑物', 'G物理'],
    industryClassValue: ['780', '501', '344', '187', '87'],
    //专利运营情况
    operation:{
        total:{ZY:8,ZR:274,XK:9},
        ZY:[
            {name:"转出",value:8},
            {name:"转入",value:0},
            {name:"内部",value:0}
        ],
        ZR:[
            {name:"转出",value:49},
            {name:"转入",value:14},
            {name:"内部",value:211}
        ],
        XK:[
            {name:"转出",value:2},
            {name:"转入",value:0},
            {name:"内部",value:7}
        ]
    },
    mapData:[{ name: "乌鲁木齐市", value: [87.617733, 43.792818, 873] },
    { name: "克拉玛依市", value: [84.873946, 45.595886, 94] },
    { name: "吐鲁番市", value: [89.184078, 42.947613, 99] },
    { name: "哈密市", value: [93.51316, 42.833248, 129] },
    { name: "昌吉回族自治州", value: [87.304012, 44.014577, 228] },
    { name: "博尔塔拉蒙古自治州", value: [82.074778, 44.903258, 21] },
    { name: "巴音郭楞蒙古自治州", value: [86.150969, 41.768552, 59] },
    { name: "阿克苏地区", value: [80.265068, 41.170712, 99] },
    { name: "克孜勒苏柯尔克孜自治州", value: [76.172825, 39.713431, 2] },
    { name: "喀什地区", value: [75.989138, 39.467664, 21] },
    { name: "和田地区", value: [79.92533, 37.110687, 15] },
    { name: "伊犁哈萨克自治州", value: [81.317946, 43.92186, 111] },
    { name: "塔城地区", value: [82.985732, 46.746301, 35] },
    { name: "阿勒泰地区", value: [88.13963, 47.848393, 6] },
    { name: "石河子市", value: [86.041075, 44.305886, 136] },
    { name: "阿拉尔市", value: [81.285884, 40.541914, 18] },
    { name: "图木舒克市", value: [79.077978, 39.867316, 1] },
    { name: "五家渠市", value: [87.526884, 44.167401, 21] },
    { name: "北屯市", value: [87.824932, 47.353177, 0] },
    { name: "铁门关市", value: [85.501218, 41.827251, 0] },
    { name: "双河市", value: [82.353656, 44.840524, 0] },
    { name: "可克达拉市", value: [80.63579, 43.6832, 0] },
    { name: "昆玉市", value: [79.287372, 37.207994, 0] },
    { name: "胡杨河市", value: [84.8275959, 44.69288853, 0] },
    ]

}

let DLData = {
    name: "电力",
    scrollTable: [
        ['01', '新疆金风科技股份有限公司', ''],
        ['02', '特变电工股份有限公司', ''],
        ['03', '特变电工新疆新能源股份有限公司', ''],
        ['04', '国网新疆电力公司电力科学研究院', ''],
        ['05', '新疆特变电工自控设备有限公司', ''],
        ['06', '新疆希望电子有限公司', ''],
        ['07', '新疆特变电工股份有限公司', ''],
        ['08', '国网新疆电力有限公司电力科学研究院', ''],
        ['09', '新疆大学', ''],
        ['10', '塔里木大学', '']
    ],
    //近五年授权量
    fImpowerNum:['327', '372', '541', '430', '480'],
    //专利类型分布 发明 新型 外观
    patentClass: [{name:"外观",value:489}, {name:"新型",value:3017}, {name:"发明",value:0}],
    industryClassName: ['H电学', 'F机械工程', 'G物理', 'B作业；运输', 'E固定建筑物'],
    industryClassValue: ['2387', '496', '421', '84', '67'],
    //专利运营情况
    operation:{
        total:{ZY:3,ZR:299,XK:31},
        ZY:[
            {name:"转出",value:3},
            {name:"转入",value:0},
            {name:"内部",value:0}
        ],
        ZR:[
            {name:"转出",value:77},
            {name:"转入",value:47},
            {name:"内部",value:175}
        ],
        XK:[
            {name:"转出",value:18},
            {name:"转入",value:4},
            {name:"内部",value:9}
        ]
    },
    mapData:[{ name: "乌鲁木齐市", value: [87.617733, 43.792818, 1950] },
    { name: "克拉玛依市", value: [84.873946, 45.595886, 108] },
    { name: "吐鲁番市", value: [89.184078, 42.947613, 60] },
    { name: "哈密市", value: [93.51316, 42.833248, 77] },
    { name: "昌吉回族自治州", value: [87.304012, 44.014577, 514] },
    { name: "博尔塔拉蒙古自治州", value: [82.074778, 44.903258, 58] },
    { name: "巴音郭楞蒙古自治州", value: [86.150969, 41.768552, 83] },
    { name: "阿克苏地区", value: [80.265068, 41.170712, 75] },
    { name: "克孜勒苏柯尔克孜自治州", value: [76.172825, 39.713431, 10] },
    { name: "喀什地区", value: [75.989138, 39.467664, 91] },
    { name: "和田地区", value: [79.92533, 37.110687, 38] },
    { name: "伊犁哈萨克自治州", value: [81.317946, 43.92186, 180] },
    { name: "塔城地区", value: [82.985732, 46.746301, 68] },
    { name: "阿勒泰地区", value: [88.13963, 47.848393, 58] },
    { name: "石河子市", value: [86.041075, 44.305886, 73] },
    { name: "阿拉尔市", value: [81.285884, 40.541914, 34] },
    { name: "图木舒克市", value: [79.077978, 39.867316, 11] },
    { name: "五家渠市", value: [87.526884, 44.167401, 11] },
    { name: "北屯市", value: [87.824932, 47.353177, 0] },
    { name: "铁门关市", value: [85.501218, 41.827251, 0] },
    { name: "双河市", value: [82.353656, 44.840524, 0] },
    { name: "可克达拉市", value: [80.63579, 43.6832, 0] },
    { name: "昆玉市", value: [79.287372, 37.207994, 0] },
    { name: "胡杨河市", value: [84.8275959, 44.69288853, 0] },
    ]

}

let LGData = {
    name: "林果业",
    scrollTable: [
        ['01', '塔里木大学', ''],
        ['02', '石河子大学', ''],
        ['03', '新疆农业大学', ''],
        ['04', '新疆农业科学院农业机械化研究所', ''],
        ['05', '新疆农垦科学院', ''],
        ['06', '中国科学院新疆理化技术研究所', ''],
        ['07', '新疆农业科学院园艺作物研究所', ''],
        ['08', '新疆大学', ''],
        ['09', '中国科学院新疆生态与地理研究所', ''],
        ['10', '安新辉', '']
    ],
    //近五年授权量
    fImpowerNum:['350', '282', '244', '160', '222'],
    //专利类型分布 发明 新型 外观
    patentClass: [{name:"外观",value:936}, {name:"新型",value:1326}, {name:"发明",value:734}],
    industryClassName: ['A人类生活必需', 'C化学；冶金', 'B作业；运输', 'G物理', 'F机械工程'],
    industryClassValue: ['1530', '327', '263', '83', '40'],
    //专利运营情况
    operation:{
        total:{ZY:2,ZR:224,XK:29},
        ZY:[
            {name:"转出",value:2},
            {name:"转入",value:0},
            {name:"内部",value:0}
        ],
        ZR:[
            {name:"转出",value:57},
            {name:"转入",value:15},
            {name:"内部",value:152}
        ],
        XK:[
            {name:"转出",value:11},
            {name:"转入",value:3},
            {name:"内部",value:15}
        ]
    },
    mapData:[{ name: "乌鲁木齐市", value: [87.617733, 43.792818, 1019] },
    { name: "克拉玛依市", value: [84.873946, 45.595886, 17] },
    { name: "吐鲁番市", value: [89.184078, 42.947613, 287] },
    { name: "哈密市", value: [93.51316, 42.833248, 155] },
    { name: "昌吉回族自治州", value: [87.304012, 44.014577, 151] },
    { name: "博尔塔拉蒙古自治州", value: [82.074778, 44.903258, 93] },
    { name: "巴音郭楞蒙古自治州", value: [86.150969, 41.768552, 183] },
    { name: "阿克苏地区", value: [80.265068, 41.170712, 393] },
    { name: "克孜勒苏柯尔克孜自治州", value: [76.172825, 39.713431, 9] },
    { name: "喀什地区", value: [75.989138, 39.467664, 48] },
    { name: "和田地区", value: [79.92533, 37.110687, 74] },
    { name: "伊犁哈萨克自治州", value: [81.317946, 43.92186, 73] },
    { name: "塔城地区", value: [82.985732, 46.746301, 16] },
    { name: "阿勒泰地区", value: [88.13963, 47.848393, 53] },
    { name: "石河子市", value: [86.041075, 44.305886, 251] },
    { name: "阿拉尔市", value: [81.285884, 40.541914, 132] },
    { name: "图木舒克市", value: [79.077978, 39.867316, 7] },
    { name: "五家渠市", value: [87.526884, 44.167401, 15] },
    { name: "北屯市", value: [87.824932, 47.353177, 0] },
    { name: "铁门关市", value: [85.501218, 41.827251, 0] },
    { name: "双河市", value: [82.353656, 44.840524, 0] },
    { name: "可克达拉市", value: [80.63579, 43.6832, 0] },
    { name: "昆玉市", value: [79.287372, 37.207994, 0] },
    { name: "胡杨河市", value: [84.8275959, 44.69288853, 0] },
    ]    
}
//加载固定数据
var data1 = 4417
var data2 = 1968
var data3 = 3499
var data4 = 2976
var SYdata5 = 41
var SYdata6 = 1016
var SYdata7 = 31
var DLdata5 = 31
var DLdata6 = 299
var DLdata7 = 3
var MTdata5 = 9
var MTdata6 = 274
var MTdata7 = 8
var LGdata5 = 29
var LGdata6 = 224
var LGdata7 = 2

export default {

    MTData,
    LGData,
    DLData,
    SYData

}